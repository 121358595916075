// @flow

import * as React from "react";

import MetaHeader from "../../components/MetaHeader/MetaHeader";
import Next from "../Next/Next";
import ScrollToTopOnMount from "../ScrollToTopOnMount/ScrollToTopOnMount";

import type { NextProps } from "../Next/Next";

import "./Page.css";

type Props = {
  children: ?React.Node,
  className: string,
  title?: string,
  hideTitle?: boolean,
  preamble?: React.Element<any>,
  next?: NextProps,
  header?: {
    description: string,
    imageURL?: string,
  },
};

const Page = (props: Props) => {
  return (
    <div className={`Page ${props.className}`}>
      <MetaHeader
        title={props.title ? `${props.title} | Ought` : "Ought"}
        {...props.header}
      />
      <ScrollToTopOnMount />
      <div className="Page-Content">
        {props.title && (
          <div>
            {!props.hideTitle && <h1 className="Page-Title">{props.title}</h1>}
          </div>
        )}
        {props.children}
      </div>
      {props.next && <Next {...props.next} />}
    </div>
  );
};

export default Page;
