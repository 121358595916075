// @flow

import * as React from "react";
import classNames from "classnames";

import TextWithPointers from "../TextWithPointers/TextWithPointers";

import "./WorkspaceElement.css";

type Props = {
  action: string | React.Node,
  observation?: string | React.Node,
  isLast?: boolean,
  standAlone?: boolean,
  register?: string | React.Node
};

const WorkspaceElement = (props: Props) => (
  <div
    className={classNames("WorkspaceElement", {
      "WorkspaceElement-IsLast": props.isLast,
      "WorkspaceElement-StandAlone": props.standAlone,
      "WorkspaceElement-HasRegister": !!props.register
    })}
  >
    {props.register && (
      <div className="WorkspaceElement-Register">{props.register}</div>
    )}
    {props.action && (
      <div className="WorkspaceElement-Action">
        <TextWithPointers text={props.action} />
      </div>
    )}
    {props.observation && (
      <div className="WorkspaceElement-Observation">
        <TextWithPointers text={props.observation} />
      </div>
    )}
  </div>
);

export const WorkspaceAction = (props: {
  text: React.Node | string,
  isLast?: boolean
}) => (
  <div
    className={classNames("WorkspaceAction", {
      "WorkspaceAction-IsLast": props.isLast
    })}
  >
    <TextWithPointers text={props.text} />
  </div>
);

export const WorkspaceObservation = (props: {
  text: React.Node | string,
  isLast?: boolean
}) => (
  <div
    className={classNames("WorkspaceObservation", {
      "WorkspaceObservation-IsLast": props.isLast
    })}
  >
    <TextWithPointers text={props.text} />
  </div>
);

export default WorkspaceElement;
