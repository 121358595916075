// @flow

import * as React from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";

import SlowJudgments from "./SlowJudgments";
import FactoredCognition from "./FactoredCognition";
import DialogMarkets from "./DialogMarkets";
import GridLayout from "../../components/GridLayout/GridLayout";

import Page from "../../components/Page/Page";
import Project from "../../components/Project/Project";
import NotFound from "../../components/NotFound/NotFound";

import "./Research.css";

const ResearchRoot = () => (
  <Page
    className="Research"
    title="Research"
    next={{
      url: "/team",
      title: "Team",
      description: "Our fledgling group",
    }}
  >
    <GridLayout>
      <div>
        <p>Ought is somewhere between a research lab and a startup.</p>
        <p>
          We do conceptual and empirical work on supporting thinking and
          reflection using machine learning, and share our findings openly. This
          research is guided by a vision for tools and applications that we hope
          will eventually help millions of people think through the questions
          and choices they face every day.
        </p>
        <p>
          We're incorporated as a non-profit, so we can afford to take the long
          view.
        </p>
      </div>
      <h2>Elicit</h2>
      <p>Our core project is Elicit, the AI research assistant:</p>
      <div className="Projects-List">
        <Project
          title="Elicit"
          url="https://elicit.org"
          imageURL="/images/icons/division.svg"
          description={
            <div>
              <p>
                Elicit helps people answer research questions by making
                qualitative reasoning steps explicit and using language models
                to incrementally automate those steps.
              </p>
              <p>
                Over the next decade, language models will increasingly automate
                aspects of human thought, but they won't automatically help us
                make sense of the world. There's a gap between providers of
                large language models (e.g. OpenAI, Anthropic, AI21) and
                researchers whose work informs corporate and government policy
                (e.g. CSET, CSER, FHI). To bridge this gap, we study and
                automate research workflow steps.
              </p>
              <p>
                You can watch{" "}
                <a href="https://www.youtube.com/channel/UCg5fl1Ht965Me_KuV84XFwg">
                  Elicit screencasts
                </a>{" "}
                on Youtube.
              </p>
            </div>
          }
          imageStyle={{ width: "75px", marginLeft: "15px" }}
        />
      </div>

      <h2>Other research</h2>
      <p>We've previously worked on:</p>
      <div className="Projects-List">
        <Project
          title="Ergo"
          url="https://github.com/oughtinc/ergo/"
          imageURL="/images/icons/ergo.svg"
          description="Integrate model-based and judgmental forecasting so that we get the best of both worlds, automated forecasts that are sensitive to qualitative arguments"
          imageStyle={{ width: "75px", marginLeft: "15px" }}
        />
        <Project
          title="Factored Cognition"
          url="/research/factored-cognition"
          imageURL="/images/icons/network.svg"
          description="Solve difficult problems by assembling small and mostly context-free contributions from individual agents who don't know the big picture"
          imageStyle={{ width: "75px", marginLeft: "15px" }}
        />
      </div>
    </GridLayout>
  </Page>
);

const Research = ({ match }) => {
  const url = match.url;
  return (
    <Switch>
      <Redirect
        from={`${url}/decomposition`}
        to={`${url}/factored-cognition`}
      />
      <Route path={`${url}`} exact component={ResearchRoot} />
      <Route path={`${url}/factored-cognition`} component={FactoredCognition} />
      <Route path={`${url}/judgments`} component={SlowJudgments} />
      <Route path={`${url}/dialog-markets`} component={DialogMarkets} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default withRouter(Research);
