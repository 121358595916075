// @flow

import * as React from "react";
import { Route, withRouter, Link, Switch } from "react-router-dom";

import FactoredCognitionMay2018 from "./FactoredCognitionMay2018";
import DelegationJune2019 from "./DelegationJune2019";
import Page from "../../components/Page/Page";
import NotFound from "../../components/NotFound/NotFound";
import GridLayout from "../../components/GridLayout/GridLayout";

const PresentationsRoot = () => (
  <Page
    className="Presentations"
    title="Presentations"
    next={{
      url: "/presentations/factored-cognition-2018-05",
      title: "Factored Cognition (May 2018)",
      description: "A presentation given at a Deepmind-FHI seminar",
    }}
  >
    <GridLayout>
      <p>We currently have two presentations available online:</p>
      <ul>
        <li>
          <Link to="/presentations/factored-cognition-2018-05">
            Factored Cognition (May 2018)
          </Link>
        </li>
        <li>
          <Link to="/presentations/delegating-cognitive-work-2019-06">
            Delegating open-ended cognitive work (June 2019)
          </Link>
        </li>
      </ul>
    </GridLayout>
  </Page>
);

const Presentations = ({ match }) => {
  const url = match.url;
  return (
    <Switch>
      <Route path={`${url}`} exact component={PresentationsRoot} />
      <Route
        path={`${url}/factored-cognition-2018-05`}
        component={FactoredCognitionMay2018}
      />
      <Route
        path={`${url}/delegating-cognitive-work-2019-06`}
        component={DelegationJune2019}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default withRouter(Presentations);
