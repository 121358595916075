// @flow

import * as React from "react";
import "./GridLayout.css";

type Props = {
  children?: React.Node,
};

const GridLayout = (props: Props) => {
  return <div className="GridLayout">{props.children}</div>;
};

export default GridLayout;
