// @flow

import * as React from "react";
import { withRouter } from "react-router-dom";

import Brand from "../Brand/Brand";
import NavigationRight from "../NavigationRight/NavigationRight";

import "./Header.css";

const Header = () => {
  return (
    <div className="Header">
      <Brand />
      <NavigationRight />
    </div>
  );
};

export default withRouter(Header);
