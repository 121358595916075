// @flow

import * as React from "react";

import Link from "../Link/Link";

import "./ImageItem.css";

type ImageItemProps = {
  imageURL: string,
  title: string,
  subtitle?: string,
  body: React.Element<any> | string,
  url?: string,
  imageStyle?: Object,
};

const ImageItem = (props: ImageItemProps) => {
  return (
    <div className="ImageItem">
      {" "}
      <div className="ImageItem-Image">
        <Link url={props.url}>
          <img src={props.imageURL} alt="" style={props.imageStyle} />
        </Link>
      </div>
      <div className="metadata">
        <div className="ImageItem-Identifier">
          <span className="ImageItem-Title">
            <Link url={props.url}>{props.title}</Link>
          </span>{" "}
          <span className="ImageItem-Subtitle"> {props.subtitle}</span>
        </div>
        <div className="ImageItem-Body">{props.body}</div>
      </div>
    </div>
  );
};

export default ImageItem;
