// @flow

import * as React from "react";

import { internalLinkSlug } from "../../routes";

type Props = {
  children?: React.Node,
  title: string,
  toc: boolean,
  slug?: string,
};

const Section = (props: Props) => {
  return (
    <div className="Section">
      <h2 id={internalLinkSlug(props.title, props.slug)}>{props.title}</h2>
      {props.children}
    </div>
  );
};

Section.defaultProps = {
  toc: true,
};

export default Section;
