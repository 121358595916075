// @flow

import * as React from "react";
import { Link } from "react-router-dom";
import GridLayout from "../../components/GridLayout/GridLayout";
import Image from "../../components/Image/Image";
import NewsletterForm from "../../components/NewsletterForm/NewsletterForm";
import Page from "../../components/Page/Page";
import ElicitDemo from "../Elicit/elicit-demo.gif";
import "./Elicit.css";

const Elicit = () => (
  <div>
    <Page
      className="Elicit"
      title="Elicit"
      next={{
        url: "/team",
        title: "Team",
        description: "Our board of directors",
      }}
    >
      <GridLayout>
        <p>
          <a href="https://elicit.com">Elicit</a> is an AI assistant for
          researchers and academics, incubated at Ought and now running as an
          independent public benefit corporation.
        </p>
        <div className="elicitVideo">
          <img alt="Elicit Demo" src={ElicitDemo} />
        </div>
        <h3>The plan for Elicit</h3>
        <p>
          Our goal is to automate and scale open-ended reasoning with language
          models—synthesizing evidence and arguments, designing research plans,
          and evaluating interventions.
        </p>
        <p>We started with automating literature reviews because:</p>
        <ol>
          <li>There is a rich discipline around synthesizing literature.</li>
          <li>
            Understanding the status quo is necessary to expand the frontier.
          </li>
          <li>Researchers most want help with literature review.</li>
        </ol>
        <p>
          Today, Elicit users find academic papers, ask questions about them,
          and summarize their findings.
        </p>
        <p>
          After literature review, Elicit will expand to other research tasks
          (evaluating project directions, decomposing research questions,
          augmented reading), then beyond research (supporting organizational
          planning, individual decision-making).
        </p>
        <p>
          Read more about{" "}
          <Link to="/updates/2022-04-08-elicit-plan">the plan for Elicit</Link>.
        </p>
        <h3>The case for Elicit</h3>
        <p>
          Robust, well-reasoned research is the bottleneck for many impactful
          interventions and decisions. Language models can address this
          bottleneck by reading and evaluating more research, evidence, and
          reasoning steps than humanly possible.
        </p>
        <p>
          Like programming languages provide building blocks for exact
          computation, language models provide the building blocks of cognitive
          work (e.g., search, extraction, classification, summarization). Elicit
          will study researchers, identify and build out these blocks, then
          surface them to users so that they can string them together and
          automate their cognitive workflows over time.
        </p>
        <p>
          If Elicit succeeds, this will make researchers vastly more productive
          and accurate. It will also help non-experts apply good research and
          reasoning practices when discovering, consuming, and generating
          information.
        </p>
        <p>
          Elicit's architecture is based on{" "}
          <Link to="/updates/2022-04-06-process">
            supervising reasoning processes, not outcomes
          </Link>
          . While this architecture is being built in the context of a research
          assistant, we expect to learn how to make machine learning useful for
          open-ended questions more broadly (differential capabilities). In the
          long run, process-based architectures can avoid some alignment risks
          posed by end-to-end optimization (alignment).
        </p>
        <ul>
          <li>
            Differential capabilities: End-to-end training based on outcomes
            doesn't work well for exceeding human capability at questions that
            don't have easily measurable outcomes, questions like "Does this
            plan have problematic long-term consequences?". If we want AI to be
            as helpful for such long-horizon tasks as it is for "Did this chat
            interaction persuade them to click 'buy'?", we need a paradigm that
            isn't based on end-to-end training.
          </li>
          <li>
            Alignment: As AI becomes more powerful, AI systems trained
            end-to-end are incentivized to game their reward metrics. The
            compositional approach evaluates process instead of outcome, thus
            providing a more robust alternative.
          </li>
        </ul>
        <p>
          Read more about{" "}
          <Link to="/updates/2022-04-06-process">
            the case for process-based architectures
          </Link>
          .
        </p>
        <h3>Infrastructure for Elicit</h3>
        <p>
          To demonstrate how to build tools like Elicit in a process-based way,
          we developed the{" "}
          <a href="https://github.com/oughtinc/ice">
            Interactive Composition Explorer
          </a>{" "}
          (ICE), an open-source Python library for compositional language model
          programs.
        </p>
        <Image
          src="/images/research/ice.png"
          caption="A language model execution trace visualized in ICE"
        />
        <p>
          To learn how to use ICE, read the{" "}
          <a href="https://primer.ought.org/">Factored Cognition Primer</a>, an
          online tutorial that walks you through solving complex reasoning
          problems using task decomposition.
        </p>
      </GridLayout>
    </Page>
    <NewsletterForm />
  </div>
);

export default Elicit;
