// @flow

import * as React from "react";

import { internalLinkSlug } from "../../routes";

type Props = {
  children?: React.Node,
  title: string,
  isLast?: boolean,
  toc: boolean,
  slug?: string,
};

const Subsection = (props: Props) => {
  return (
    <div className="Subsection">
      <h3 id={internalLinkSlug(props.title, props.slug)}>{props.title}</h3>
      {props.children}
    </div>
  );
};

Subsection.defaultProps = {
  toc: true,
};

export default Subsection;
