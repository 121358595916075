// @flow

import * as React from "react";
import { Link } from "react-router-dom";

import ImageItem from "../../../components/ImageItem/ImageItem";
import Page from "../../../components/Page/Page";
import GridLayout from "../../../components/GridLayout/GridLayout";

import "./Overview.css";

const subURL = (name) => `/research/factored-cognition/${name}`;

const chapters = [
  {
    title: "Scalable mechanisms for solving cognitive tasks",
    text: "We'd like to build general-purpose question-answering systems that produce increasingly helpful solutions as they get access to more human work-hours and better ML algorithms.",
    icon: "mechanism.svg",
    slug: "scalability",
  },

  {
    title: "A taxonomy of approaches to capability amplification",
    text: "The space of features that such systems could use includes recursion, pointers to data and to agents, edits, persistent workspaces, structured content, reflection, meta-execution, and different approaches to interaction with the outside world.",
    icon: "taxonomy.svg",
    slug: "taxonomy",
  },

  {
    title: "A set of tasks for evaluating scalable problem solving",
    text: "To evaluate whether a system might be scalable, we propose a set of trial tasks: answering questions about books, fact checking, math textbook exercises, cost-benefit analysis, and todo prioritization.",
    icon: "tasks.svg",
    slug: "tasks",
  },

  {
    title:
      "Networks of Workspaces: A user-friendly approach to capability amplification",
    text: "Our best guess for how to build a scalable question-answering system is to use recursion, pointers, edits, persistence, and (later) interaction via indirection and reflection.",
    icon: "network.svg",
    slug: "networks-of-workspaces",
  },
];

type BackgroundItemProps = {
  title: string,
  text: string,
  icon: string,
  slug: string,
};

const BackgroundItem = (props: BackgroundItemProps) => (
  <ImageItem
    title={props.title}
    imageURL={`/images/icons/${props.icon}`}
    url={subURL(props.slug)}
    body={props.text}
    imageStyle={{ width: "60px", marginLeft: "30px" }}
  />
);

const Overview = () => {
  return (
    <Page
      className="Overview Page-ArticleLike"
      title="Factored Cognition"
      next={{
        url: "/research/factored-cognition/scalability",
        title: "Scalable mechanisms for solving cognitive tasks",
      }}
      header={{
        description:
          "Can we solve difficult problems by composing small contributions from individual agents who don't know the big picture?",
        imageURL: "https://ought.org/images/factored-cognition-screenshot.png",
      }}
    >
      <GridLayout>
        <p>
          In this project, we explore whether we can solve difficult problems by
          composing small and mostly context-free contributions from individual
          agents who don't know the big picture.
        </p>

        <h2>What is factored cognition?</h2>

        <p>
          Imagine the set of questions you can answer in 15 minutes using a
          computer. There's a lot you can do. You can look up facts, do
          calculations, weigh considerations, and thus answer many questions
          that require a bit of research and deliberation. But there's also a
          lot you can't do. If a question is about some field of physics you've
          never heard of, say "What does a field theory look like in which
          supersymmetry is spontaneously broken?", you probably won't have time
          to learn enough to give a good answer.
        </p>

        <p>
          Now, consider a thought experiment: Imagine that during your 15
          minutes you can delegate up to 100 tasks to fast copies of yourself.
          That is, assistants who are just as capable and motivated as you are,
          and also only have 15 minutes of subjective time, but who are much
          faster, so that when you delegate a task, you immediately observe
          their answer. Clearly, you can do a lot more with the help of your 100
          assistants than you could on your own. We'll call this a{" "}
          <strong>one-step amplification</strong> of yourself.
        </p>

        <p>
          What if we iterated this process, so that each of your assistants in
          turn had access to 100 assistants, and so on? What capabilities could
          we implement through{" "}
          <strong>
            <a href="https://ai-alignment.com/alphago-zero-and-capability-amplification-ede767bb8446">
              iterated amplification
            </a>
          </strong>
          , and what tasks would stay out of reach, if any?
        </p>

        <p>
          <strong>Factored cognition</strong> refers to mechanisms like this,
          where sophisticated learning and reasoning is broken down (or
          factored) into many small and mostly independent tasks.
        </p>

        <h2>Why does it matter?</h2>

        <p>
          Our <Link to="/mission">mission</Link> is to find scalable ways to
          leverage machine learning for deliberation. This requires that we can
          view thinking as a task with data that we can train ML systems on. The
          only concrete way we know for doing that is to record how people
          deliberate using explicit actions in narrow contexts, i.e. to break
          deliberation into many little tasks. In that case, we can use ML to
          imitate what people do and scale up by repeatedly applying the learned
          policy. This is a central component of{" "}
          <a href="https://ai-alignment.com/iterated-distillation-and-amplification-157debfd1616">
            Iterated Distillation and Amplification
          </a>
          , Paul Christiano's approach to AI Alignment.
        </p>

        <h2 id="chapters">Chapters</h2>
        <div className="Overview-BackgroundItemList">
          {chapters.map((chapter) => (
            <BackgroundItem key={chapter.title} {...chapter} />
          ))}
        </div>

        <h2>Resources</h2>
        <ul>
          <li>
            <a href="https://github.com/oughtinc/ice">
              Interactive Composition Explorer (September 2022)
            </a>{" "}
            <br />A Python library for writing and debugging compositional
            language model programs.
          </li>
          <li>
            <a href="https://primer.ought.org/">
              Factored Cognition Primer (September 2022)
            </a>{" "}
            <br />A tutorial that shows using examples how to write
            compositional language models programs.
          </li>
          <li>
            <Link to="/updates/2020-01-11-arguments">
              Evaluating Arguments One Step at a Time (January 2020)
            </Link>{" "}
            <br />A technical report on our experiments with factored evaluation
            of structured arguments for claims about movie reviews.
          </li>

          <li>
            <Link to="/presentations/delegating-cognitive-work-2019-06">
              Delegating open-ended cognitive work (June 2019)
            </Link>{" "}
            <br />A presentation that explains the challenges of delegating
            open-ended question-answering to experts and demos our{" "}
            <em>factored evaluation</em> experiments.
          </li>
          <li>
            <Link to="/presentations/factored-cognition-2018-05">
              Factored Cognition (May 2018)
            </Link>{" "}
            <br />A presentation that motivates the factored cognition project
            from an AI alignment angle and reports on the state of work in May
            2018.
          </li>
          <li>
            <a href="https://github.com/oughtinc/mosaic">Mosaic</a>
            <br />A prototype for a web app that supports recursive
            decomposition of questions with pointers.
          </li>
          <li>
            <a href="https://github.com/oughtinc/patchwork">Patchwork</a>
            <br />
            An open-source command-line app for recursive decomposition of
            questions, built to be the foundation for a web app with multiple
            users and automation.
          </li>
          <li>
            <a href="https://github.com/oughtinc/affable">Affable</a>
            <br />
            The Haskell-based successor to Patchwork.
          </li>
          <li>
            <a href="https://relay.ought.org">Relay Programming</a>
            <br />A collaborative programming experiment designed to explore
            factored cognition in the context of solving <em>Project Euler</em>
            -style programming puzzles.
          </li>
        </ul>

        <p className="Overview-Thanks">
          Thanks to Paul Christiano, Rohin Shah, Daniel Dewey, Owain Evans,
          Andrew Critch, William Saunders, Ozzie Gooen, Ryan Carey, Jeff Wu, and
          Jan Leike for feedback on the chapters.
        </p>
      </GridLayout>
    </Page>
  );
};

export default Overview;
