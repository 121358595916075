// @flow

import * as React from "react";
import { withRouter, Route, Switch } from "react-router-dom";

import Overview from "./FactoredCognition/Overview";
import Scalability from "./FactoredCognition/Scalability";
import Taxonomy from "./FactoredCognition/Taxonomy";
import Tasks from "./FactoredCognition/Tasks";
import NetworksOfWorkspaces from "./FactoredCognition/NetworksOfWorkspaces";
import NotFound from "../../components/NotFound/NotFound";

const FactoredCognition = ({ match }) => {
  const url = match.url;
  return (
    <Switch>
      <Route path={`${url}`} exact component={Overview} />
      <Route path={`${url}/scalability`} component={Scalability} />
      <Route path={`${url}/taxonomy`} component={Taxonomy} />
      <Route path={`${url}/tasks`} component={Tasks} />
      <Route
        path={`${url}/networks-of-workspaces`}
        component={NetworksOfWorkspaces}
      />
      <Route path={`${url}/networks-of-workspaces`} component={NotFound} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default withRouter(FactoredCognition);
