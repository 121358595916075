// @flow

import _ from "lodash";
import assert from "assert";
import * as React from "react";
import Tooltip from "react-simple-tooltip";

import "./TextWithPointers.css";

export function interleave<T, U>(xs: Array<T>, ys: Array<U>): Array<T | U> {
  assert.ok(xs.length === ys.length || xs.length === ys.length + 1);
  const result = [];
  for (let i = 0; i < Math.min(xs.length, ys.length); i += 1) {
    result.push(xs[i]);
    result.push(ys[i]);
  }
  if (xs.length > ys.length) {
    result.push(_.last(xs));
  }
  return result;
}

const AgentPointerTooltip = (props: { text: string }) => (
  <div className="AgentPointerTooltip">
    <p>
      <span className="TextWithPointers-Pointer-Tooltip-Pointer">
        {props.text}
      </span>{" "}
      is a pointer to some agent.
    </p>
    <p>
      H can send questions to this agent by executing{" "}
      <span className="TextWithPointers-Pointer-Tooltip-Text-Action">
        {props.text} {"<question>"}
      </span>.
    </p>
    <p>
      H can also pass the pointer to other agents without looking at its
      content.
    </p>
  </div>
);

const DataPointerTooltip = (props: { text: string }) => (
  <div className="DataPointerTooltip">
    <p>
      <span className="TextWithPointers-Pointer-Tooltip-Pointer">
        {props.text}
      </span>{" "}
      is a pointer to data.
    </p>
    <p>
      H can reveal its content by executing{" "}
      <span className="TextWithPointers-Pointer-Tooltip-Text-Action">
        View {props.text}
      </span>. This content may include further pointers.
    </p>
    <p>
      H can also pass the pointer to other agents without looking at its
      content.
    </p>
  </div>
);

const Pointer = (props: { text: string }) => (
  <span className="TextWithPointers-Pointer">
    <Tooltip
      className="TextWithPointers-Pointer-Tooltip"
      content={
        <div className="TextWithPointers-Pointer-Tooltip-Text">
          {props.text.startsWith("@") ? (
            <AgentPointerTooltip text={props.text} />
          ) : (
            <DataPointerTooltip text={props.text} />
          )}
        </div>
      }
    >
      {props.text}
    </Tooltip>
  </span>
);

const TextWithPointers = (props: { text: React.Node | string }) => {
  const text = props.text;
  if (typeof text === "string") {
    const pointerRegex = /[#@][0-9]/g;
    const texts = text.split(pointerRegex);
    const pointers = text.match(pointerRegex);
    if (pointers) {
      const pointerNodes = pointers.map(pointerText => (
        <Pointer key={pointerText} text={pointerText} />
      ));
      return (
        <span className="TextWithPointers">
          {interleave(texts, pointerNodes)}
        </span>
      );
    }
  }
  return <span className="TextWithPointers">{text}</span>;
};

export default TextWithPointers;
