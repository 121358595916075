// @flow

import * as React from "react";
import { Link as ReactRouterLink } from "react-router-dom";

type Props = {
  children: React.Element<any> | string,
  url?: string
};

const Link = (props: Props) => {
  if (props.url) {
    if (props.url.startsWith("http")) {
      return <a href={props.url}>{props.children}</a>;
    } else {
      return <ReactRouterLink to={props.url}>{props.children}</ReactRouterLink>;
    }
  } else {
    return props.children;
  }
};

export default Link;
