// @flow

import * as React from "react";
import { render } from "react-snapshot";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import App from "./components/App/App";
import "./index.css";

// TODO: Extract redirects using fragments once I've upgraded to React 16.2+

render(
    <Router>
    <Switch>
    <Redirect from="/approach/" to="/elicit" exact />
    <Redirect from="/projects/" to="/elicit" exact />
    <Redirect from="/research/" to="/elicit" exact />        
    <Redirect from="/blog/" to="/updates" exact />
    <Redirect
    from="/careers/web-developer"
    to="/careers/software-engineer"
    exact
    />
    <Redirect
    from="/careers/engineering-lead"
    to="/careers/engineering-manager"
    exact
    />
    <Route
    path="/blog/:slug"
    render={({ match }) => (
        <Redirect to={`/updates/${match.params.slug}/`} />
    )}
    exact
    />
    <Route
    path="/projects/:projectName"
    render={({ match }) => (
        <Redirect to={`/research/${match.params.projectName}/`} />
    )}
        exact
      />
      <Route
        path="/projects/:projectName/:projectPage"
        render={({ match }) => (
          <Redirect
            to={`/research/${match.params.projectName}/${match.params.projectPage}`}
          />
        )}
      />
      <Route path="/" component={App} />
    </Switch>
  </Router>,
  document.getElementById("root")
);

// registerServiceWorker();
