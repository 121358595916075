// @flow

import * as React from "react";

import TableOfContents from "../TableOfContents/TableOfContents";
import "../../components/LocalNavigation/LocalNavigation.css"; // Used directly in TOC
import "../../components/TableOfContents/TableOfContents.css"; // Used directly in TOC

import "./Article.css";

type Props = {
  children?: React.Node,
  summary?: ?React.Element<any> | string,
  tocHTML?: ?string,
  short?: boolean,
};

const Article = (props: Props) => {
  return (
    <div className="Article">
      {!props.short && (
        <div className="tocContainer">
          {props.tocHTML ? (
            <div dangerouslySetInnerHTML={{ __html: props.tocHTML }} />
          ) : (
            <TableOfContents id="toc">{props.children}</TableOfContents>
          )}
        </div>
      )}
      {props.children}
    </div>
  );
};

export default Article;
