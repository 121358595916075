// @flow

import * as React from "react";
import { withRouter, Route, Switch } from "react-router-dom";

import Overview from "./DialogMarkets/Overview";
import DALCA from "./DialogMarkets/DALCA";
import Microtasks from "./DialogMarkets/Microtasks";
import NotFound from "../../components/NotFound/NotFound";

const DialogMarkets = ({ match }) => {
  const url = match.url;
  return (
    <Switch>
      <Route path={`${url}`} exact component={Overview} />
      <Route path={`${url}/microtasks`} component={Microtasks} />
      <Route path={`${url}/dalca`} component={DALCA} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default withRouter(DialogMarkets);
