// @flow

import { withRouter } from "react-router-dom";
import "./Careers.css";

const Careers = ({ match }) => {
  window.location.href = "https://elicit.com/careers";
  return null;
};

export default withRouter(Careers);
