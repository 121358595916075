// @flow

import _ from "lodash";
import * as React from "react";

import { internalLinkURL } from "../../routes";
import LocalNavigation from "../LocalNavigation/LocalNavigation";

import "./TableOfContents.css";

type InternalProps = {
  elements: React.Node
};

const InternalTableOfContents = (props: InternalProps) => {
  const entries = React.Children.map(props.elements, child =>
    child && child.props && child.props.title && child.props.toc ? (
      <li>
        <a href={internalLinkURL(child.props.title, child.props.slug)}>
          {child.props.title}
        </a>
        {child.props.children ? (
          <InternalTableOfContents elements={child.props.children} />
        ) : null}
      </li>
    ) : null
  );
  return _.find(entries) ? <ol>{entries}</ol> : null;
};

type Props = {
  children: React.Node,
  id?: string
};

const TableOfContents = (props: Props) => (
  <LocalNavigation id={props.id}>
    <div className="TableOfContents">
      <div className="TableOfContents-Header">Table of Contents</div>
      <InternalTableOfContents elements={props.children} />
    </div>
  </LocalNavigation>
);

export default TableOfContents;
