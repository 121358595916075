// @flow

import * as React from "react";
import { Route, withRouter, Link, Switch } from "react-router-dom";

import NotFound from "../../components/NotFound/NotFound";
import { BlogPostPage } from "./Post";
import NewsletterForm from "../../components/NewsletterForm/NewsletterForm";
import MetaHeader from "../../components/MetaHeader/MetaHeader";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount/ScrollToTopOnMount";

import posts from "../../posts";

import "./Blog.css";

type HeadlineProps = {
  limit: number,
};

const HeadlineSummaryPosts = (props: HeadlineProps) => {
  return (
    <div className="HeadlineSummaryPosts">
      <h1 className="Page-Title">Updates</h1>
      {posts.slugs.slice(0, props.limit).map((slug) => {
        const description = posts.data.get(slug).header.description;
        return (
          <Link to={`/updates/${slug}`} key={slug}>
            <div className="SinglePost" key={slug}>
              <svg
                width="43"
                height="54"
                viewBox="0 0 43 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  y="31"
                  width="8"
                  height="29"
                  transform="rotate(-90 0 31)"
                />
                <rect
                  x="29.385"
                  y="31.4446"
                  width="8"
                  height="24"
                  transform="rotate(50 29.385 31.4446)"
                />
                <rect
                  width="8"
                  height="24"
                  transform="matrix(0.642788 -0.766044 -0.766044 -0.642788 29.385 22)"
                />
                <rect
                  width="8"
                  height="16"
                  transform="matrix(1 0 0 -1 35 16)"
                />
                <rect x="35" y="38" width="8" height="16" />
              </svg>

              <div>
                <h1>{posts.title(slug)}</h1>
                {description !== undefined && <p>{description}</p>}
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

const BlogRoot = () => {
  const slug = posts.slugs[0];
  const numHeadlines = 24;
  const post = posts.data.get(slug);
  if (!post) {
    console.warn(`Couldn't find post for slug ${slug}`);
    return null;
  }
  return (
    <div className="BlogIndexPage">
      <MetaHeader title="Updates" />
      <ScrollToTopOnMount />
      <HeadlineSummaryPosts limit={numHeadlines} />
      <NewsletterForm />
    </div>
  );
};

const Blog = ({ match }) => {
  const url = match.url;
  return (
    <Switch>
      <Route path={`${url}`} exact component={BlogRoot} />
      {posts.slugs.map((slug) => {
        const post = posts.data.get(slug);
        if (!post) {
          console.warn(`Couldn't find post for slug ${slug}`);
          return null;
        }
        return (
          <Route
            key={slug}
            path={`${url}/${slug}`}
            component={BlogPostPage(post)}
          />
        );
      })}
      <Route component={NotFound} />
    </Switch>
  );
};

export default withRouter(Blog);
