// @flow

import * as React from "react";
import { Link } from "react-router-dom";
import type { ColumnData, RowData } from "./types";

export const columns: Array<ColumnData> = [
  {
    title: "Recursion",
    accessor: "recursion"
  },
  {
    title: "Pointers",
    accessor: "pointers"
  },
  {
    title: "Internal dialog",
    accessor: "internalDialog"
  },
  {
    title: "Edits",
    accessor: "edits"
  },
  {
    title: "Persistence",
    accessor: "persistence"
  },
  {
    title: "Structured content",
    accessor: "structuredContent"
  },
  {
    title: "Reflection",
    accessor: "reflection"
  },
  {
    title: "Meta-execution",
    accessor: "metaExecution"
  },
  {
    title: "Interaction",
    accessor: "interaction"
  }
];

export const comments = {
  requiresRecursion:
    "Internal dialog and persistence (in the sense used here) require recursion.",
  easyViaIndirection:
    "This feature isn’t an explicit component of this system, but any system with pointers can easily implement it via indirection.",
  editsIfRegisters:
    "Registers allow edits. Whether to use append-only lists of questions & answers or registers is a contingent choice, not a key component of the system."
};

export const templates = {
  requiresRecursion: {
    value: "n/a",
    comment: comments.requiresRecursion,
    className: "Table-na"
  },
  qaList: (
    <a href="/research/factored-cognition/taxonomy#recursion-example">
      q&a list
    </a>
  ),
  registers: (
    <a href="/research/factored-cognition/taxonomy#edits">registers</a>
  ),
  indirection: (
    <a href="/research/factored-cognition/taxonomy#indirection">indirection</a>
  ),
  indirectionWithPersistence: (
    <a href="/research/factored-cognition/taxonomy#indirection">
      indirection + persistence
    </a>
  )
};

export const rows: Array<RowData> = [
  {
    name: "one-shot-qa",
    title: <a href="/research/factored-cognition/taxonomy#qa">One-shot Q&A</a>,
    internalDialog: templates.requiresRecursion,
    persistence: templates.requiresRecursion,
    structuredContent: "prose"
  },
  {
    name: "qa-edits",
    title: "Q&A with edits",
    internalDialog: templates.requiresRecursion,
    edits: true,
    persistence: templates.requiresRecursion,
    structuredContent: "prose"
  },
  {
    name: "chat",
    title: "Chat",
    internalDialog: templates.requiresRecursion,
    persistence: templates.requiresRecursion,
    structuredContent: "prose",
    interaction: (
      <a href="/research/factored-cognition/taxonomy#resourcePointers">
        resource pointers
      </a>
    )
  },
  {
    name: "workflowy",
    title: {
      value: <a href="https://workflowy.com">Workflowy</a>,
      comment:
        "One could alternatively classify Workflowy as a mechanism with recursion (without return values), persistence, and prose content. Both approaches are lossy. The approach used in the table probably reflects single-person use better."
    },
    internalDialog: templates.requiresRecursion,
    edits: true,
    persistence: templates.requiresRecursion,
    structuredContent: (
      <a href="https://en.wikipedia.org/wiki/Outliner">outline</a>
    )
  },
  {
    name: "wikipedia",
    title: <a href="https://en.wikipedia.org">Wikipedia</a>,
    recursion: {
      value: false,
      comment:
        "Wiki pages can link to other pages, but those other pages don’t “return” results that the current page depends on."
    },
    edits: true,
    persistence: true,
    structuredContent: (
      <a href="https://en.wikipedia.org/wiki/Help:Wikitext">wikitext</a>
    )
  },
  {
    name: "weak-hch",
    title: (
      <a href="https://ai-alignment.com/humans-consulting-hch-f893f6051455">
        Weak HCH
      </a>
    ),
    recursion: true,
    structuredContent: templates.qaList
  },
  {
    name: "strong-hch",
    title: (
      <span>
        <a href="https://ai-alignment.com/strong-hch-bedb0dc08d4e">
          Strong HCH
        </a>{" "}
        (
        <a href="https://github.com/paulfchristiano/alba/blob/master/amplify/hch.py">
          1
        </a>
        , <a href="http://github.com/oughtinc/hch">2</a>)
      </span>
    ),
    recursion: true,
    internalDialog: true,
    structuredContent: templates.qaList
  },
  {
    name: "annotated-fp",
    title: (
      <a href="https://ai-alignment.com/approval-directed-algorithm-learning-bf1f8fad42cd#bb41">
        Annotated functional programming
      </a>
    ),
    recursion: true,
    pointers: true,
    edits: true,
    structuredContent: templates.registers,
    interaction: templates.indirection
  },
  {
    name: "meta-execution",
    title: {
      value: (
        <span>
          <a href="https://ai-alignment.com/meta-execution-27ba9b34d377">
            Meta-execution
          </a>{" "}
          (
          <a href="https://github.com/paulfchristiano/alba/blob/master/amplify/meta.py">
            1
          </a>
          )
        </span>
      ),
      comment:
        "I’m distinguishing meta-execution as a system (row) from the meta-execution indirection as a feature that can be applied to many systems (column)."
    },
    recursion: true,
    pointers: true,
    internalDialog: true,
    edits: {
      comment: comments.editsIfRegisters
    },
    structuredContent: {
      value: (
        <span>
          {templates.qaList} or {templates.registers}
        </span>
      ),
      comment: comments.editsIfRegisters
    },
    metaExecution: true,
    interaction: {
      value: templates.indirection,
      comment: comments.easyViaIndirection
    }
  },
  {
    name: "dwimmer",
    title: <a href="https://github.com/paulfchristiano/dwimmer">Dwimmer</a>,
    recursion: true,
    pointers: true,
    internalDialog: true,
    edits: true,
    structuredContent: templates.registers,
    reflection: true,
    metaExecution: {
      comment: comments.easyViaIndirection
    },
    interaction: {
      value: templates.indirection,
      comment: comments.easyViaIndirection
    }
  },
  {
    name: "dialog-markets",
    title: <Link to="/research/dialog-markets">Dialog markets</Link>,
    recursion: true,
    edist: true,
    persistence: true,
    structuredContent: (
      <a href="https://en.wikipedia.org/wiki/Outliner">outline</a>
    ),
    interaction: (
      <a href="https://stuhlmueller.org/dialog-markets/figures/web.html">
        top-level queue
      </a>
    )
  },
  {
    name: "networks-of-workspaces",
    title: (
      <Link to="/research/factored-cognition/networks-of-workspaces">
        Networks of workspaces
      </Link>
    ),
    recursion: true,
    pointers: true,
    edits: true,
    persistence: true,
    structuredContent: (
      <Link to="/research/factored-cognition/networks-of-workspaces#structuredContent">
        markup?
      </Link>
    ),
    reflection: true,
    interaction: templates.indirectionWithPersistence
  }
];
