// @flow

import * as React from "react";

import "./Image.css";

type ImageProps = {
  src: string,
  caption: string,
};

const Image = (props: ImageProps) => (
  <div className="Image">
    <a href={props.src} target="_blank" rel="noopener noreferrer">
      <img src={props.src} alt={props.caption} />
    </a>
    <div className="Image-Caption">{props.caption}</div>
  </div>
);

export default Image;
