// @flow

import * as React from "react";

import ImageItem from "../ImageItem/ImageItem";

import "./Person.css";

type Props = {
  name: string,
  position: string,
  imageURL: string,
  bio: React.Element<any>,
  url?: string
};

const Person = (props: Props) => (
  <ImageItem
    title={props.name}
    imageURL={props.imageURL}
    subtitle={props.position}
    body={props.bio}
    url={props.url}
  />
);

export default Person;
