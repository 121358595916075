// @flow

// This file requires webpack to work.

import _ from "lodash";

import parsePosts from "./parse-posts";

export type { Post } from "./parse-posts";

function webpackLoadPostFiles() {
  const context = (require: any).context(
    "!markdown-with-front-matter-loader!./posts/",
    false,
    /.md$/
  );
  return _.fromPairs(
    context.keys().map((filename) => {
      const tokens = filename.match(/.\/([^.]+).*/);
      const slug = tokens[1];
      return [slug, context(filename)];
    })
  );
}

const files = webpackLoadPostFiles();
const posts = parsePosts(files, new window.DOMParser());

export default posts;
