// @flow

import * as React from "react";
import { Link } from "react-router-dom";

import Section from "../../../components/Section/Section";
import Subsection from "../../../components/Subsection/Subsection";
import Page from "../../../components/Page/Page";
import TableOfMechanisms from "../../../components/TableOfMechanisms/TableOfMechanisms";
import Workspace from "../../../components/Workspace/Workspace";
import WorkspaceElement, {
  WorkspaceAction,
  WorkspaceObservation,
} from "../../../components/WorkspaceElement/WorkspaceElement";
import GridLayout from "../../../components/GridLayout/GridLayout";

import CognitiveTasks from "./CognitiveTasks";

const Taxonomy = () => (
  <Page
    className="Taxonomy"
    title="A taxonomy of approaches to capability amplification"
    next={{
      url: "/research/factored-cognition/tasks",
      title: "A set of tasks for evaluating scalable problem solving",
    }}
  >
    <GridLayout>
      <Section title="Summary">
        <div>
          <p>
            We'd like to design mechanisms for solving cognitive tasks that are{" "}
            <Link to="/research/factored-cognition/scalability">scalable</Link>{" "}
            with respect to human work. This page reviews ideas that could be
            part of such mechanisms.
          </p>
          <p>Here are some examples of cognitive tasks:</p>
          <CognitiveTasks />
          <p>
            Below, H refers to a human worker who has 15 minutes to solve a
            short task, and f(x, n) refers to an algorithm that takes a task x
            and a number n of calls to the human worker, and then produces a
            solution to x while making at most n calls to H.
          </p>
          <p>
            We’ll start with simple ideas that are definitely not sufficient on
            their own if we want to solve non-trivial problems, but will form a
            basis for later ideas.
          </p>
          <p>
            This page is mostly a review of Paul Christiano’s work on{" "}
            <a href="https://ai-alignment.com/policy-amplification-6a70cbee4f34">
              amplification
            </a>{" "}
            and (to a lesser extent) our work on{" "}
            <Link to="/research/dialog-markets">dialog markets</Link>, with a
            few new bits here and there.
          </p>
        </div>
      </Section>
      <Section title="Q&A: One-shot question-answering" slug="qa">
        <p>
          As a starting point, we can pass the task to H and return what they
          return:
        </p>
        <pre>f(x, n) = H(x)</pre>
        <p>This will only solve problems that H can solve in 15 minutes.</p>
      </Section>
      <Section title="Iteration: A sequence of short tasks" slug="iteration">
        <p>
          A second idea is to increase the total time available for solving the
          task by calling H n times, each time providing the problem x, the
          number of calls remaining, and the previous partial answer. For n=3,
          this looks like this:
        </p>
        <pre>f(x, n=3) = H(x, 0, H(x, 1, H(x, 2, "init")))</pre>
        <p>
          This is already much more powerful. It’s question-answering with a
          potentially very large number of steps for thinking, sketching, and
          revising. Each instance of H can leave notes for its successor, as
          long as the notes can be written and read within the available time.
        </p>
      </Section>

      <Section
        title="Recursion: Making sub-calls to agents that make sub-calls"
        slug="recursion"
      >
        <p>
          As an alternative to iteration, we can allow each instance of H to
          make sub-calls to further instances, up to a global limit of n calls:
        </p>
        <pre>{"f(x, n) = H(x, H_{limit n})"}</pre>
        <p>
          The limiting case where n is unbounded is{" "}
          <a href="https://ai-alignment.com/humans-consulting-hch-f893f6051455">
            HCH
          </a>
          .
        </p>
        <p>
          However, we want to actually implement systems, and ideally get
          guarantees of roughly the following form: “For any task and any
          ‘achievable’ solution quality, there is a finite amount of computation
          that allows the system to solve the task at that quality.” This
          requires that we define how f behaves for finite n.
        </p>
        <Subsection title="Example" toc={false} slug="recursion-example">
          <p>
            Suppose the task x is “What is 351 * 5019?” I’ll represent the
            workspace that H sees using a table with three elements: The
            question, a body that contains the sub-questions H has asked so far,
            and potentially the reply H has sent. (H is done after sending the
            reply; I’m including it for our purposes here.)
          </p>
          <Workspace question="What is 351 * 5019?" />
          <p>
            H can now ask sub-questions and they will appear in the body along
            with their answers:
          </p>
          <Workspace question="What is 351 * 5019?">
            <WorkspaceElement
              action="What is 300 * 5019?"
              observation="1505700"
            />
            <WorkspaceElement
              action="What is 50 * 5019?"
              observation="250950"
              isLast={true}
            />
          </Workspace>
          <p>
            Each sub-question is handled by a separate instance of H, which sees
            a workspace of the same shape and which can herself ask
            sub-questions.
          </p>
          <p>
            Eventually, top-level H has sufficient information to return a
            response:
          </p>
          <Workspace question="What is 351 * 5019?" response="1761669">
            <WorkspaceElement
              action="What is 300 * 5019?"
              observation="1505700"
            />
            <WorkspaceElement
              action="What is 50 * 5019?"
              observation="250950"
            />
            <WorkspaceElement
              action="What is 1505700 + 250950 + 5019?"
              observation="1761669"
              isLast={true}
            />
          </Workspace>
        </Subsection>
        <Subsection title="Issues" toc={false} isLast={true}>
          <p>This example raises two issues:</p>
          <h4>Do we assume that each sub-call returns immediately?</h4>
          <p>
            For thinking about the abstract problem, this seems cleanest. If
            sub-calls take real time, the time available for the entire tree of
            agents is limited by the time the top-level agent H has, defeating
            the purpose of sub-calls.
          </p>
          <p>
            For implementations with real humans, our options and their
            corresponding disadvantages are:
          </p>
          <ol>
            <li>
              Pause the timer for H while waiting on sub-calls. This effectively
              gives H more time to think and thus breaks one of the key
              constraints for this project.
            </li>
            <li id="sub-call-strategy">
              Pause the timer while waiting on sub-calls, and also emulate H
              using multiple workers. That is, worker 1 handles everything up to
              the first sub-call, worker 2 everything up to the next one, etc.
              This destroys any internal state that workers might have built up
              before they initiated their sub-call. This is probably acceptable
              if we allow workers to add notes to the workspace.
            </li>
            <li>
              Require H to specify a simple program that is used to combine the
              results of sub-calls. This limits H to computations that are
              tail-recursive with respect to human computation.
            </li>
          </ol>
          <p>Of these, 2 seems best to me.</p>
          <h4 id="budgets">
            How do we implement the limit on the total number of sub-calls?
          </h4>
          <p>
            We can associate a budget with each context (workspace state). When
            H makes a sub-call, she specifies what portion of her budget the
            sub-call may use (at most). When the sub-call returns, it also
            returns how much budget it used up, and this is deducted from the
            budget for H’s workspace. (This is the strategy followed by Paul’s{" "}
            <a href="https://github.com/paulfchristiano/alba/">
              ALBA implementation
            </a>
            .)
          </p>
        </Subsection>
      </Section>

      <Section title="Pointers: Handling data abstractly" slug="pointers">
        <p>
          So far, the size of the data that the collection of Hs can handle has
          been bounded by the constraint that all information needs to be passed
          explicitly between different instances of H. This means that the
          mechanisms so far would fail even on simple tasks like sorting a list
          with a million elements, since we wouldn’t be able to communicate the
          list to the first instance of H. We can address this by introducing
          pointers to data.
        </p>
        <p>
          A simple way to implement this is using nested messages (as introduced
          in{" "}
          <a href="https://ai-alignment.com/approval-directed-algorithm-learning-bf1f8fad42cd#bb41">
            annotated functional programming
          </a>
          ).
        </p>
        <p>
          We’ve previously considered that H could ask the sub-question “What is
          300 * 5019?” Instead, H could use square brackets to designate certain
          parts of the question as sub-messages:
        </p>
        <WorkspaceElement standAlone={true} action="What is [300] * [5019]?" />
        <p>
          In this case, the next instance of H (let’s call it H<sub>2</sub>)
          sees a message with opaque pointers to data:
        </p>
        <WorkspaceElement standAlone={true} action="What is #1 * #2?" />
        <p>
          H<sub>2</sub> can pass these pointers to sub-questions without looking
          at their values:
        </p>
        <WorkspaceElement standAlone={true} action="Is #1 equal to 1?" />
        <p>
          Alternatively, H<sub>2</sub> can expand a pointer to reveal its value:
        </p>
        <WorkspaceElement
          standAlone={true}
          action="View #2"
          observation="5019"
        />
        <p>
          Using pointers, we can provide large datasets to bounded agents. For
          example, we could ask H to summarize a book represented as a linked
          list. Here H looks at the first few sentences:
        </p>
        <Workspace question="Summarize the book #1.">
          <WorkspaceElement
            action="View #1"
            observation="'Who is John Galt?' (cont'd: #2)"
          />
          <WorkspaceElement
            action="View #2"
            observation="The light was ebbing, and Eddie Willers could not distinguish the bum's face. (cont'd: #3)"
          />
          <WorkspaceElement
            action="View #3"
            observation="The bum had said it simply, without expression. (cont'd: #4)"
            isLast={true}
          />
        </Workspace>
        <p>
          Pointers don’t always have to be explicit. If we show a page in a book
          with arrows for flipping to the previous/next page, we can think about
          this as a combination of pointers and <a href="#edits">edits</a>.
        </p>
        <p>
          We don't have to implement pointers using nested messages. Any
          approach to constructing functional data structures will do. The
          reason to be functional is that this makes it easy to describe the
          intended behavior of H: "Return what is most helpful to the agent that
          instantiated you." If mutations can affect computations far away in
          the tree of agents in ways that don't flow through the parent, the
          intended behavior is less clear. (We'll see clean ways to allow
          mutation when we talk about <a href="#interaction">interaction</a>.)
        </p>
        <p>
          A mechanism with recursion (or iteration) and pointers is
          computationally universal:
        </p>
        <ul>
          <li>
            Given that our evaluation and data model are close to Scheme,
            implementing{" "}
            <a href="https://mitpress.mit.edu/sicp/full-text/sicp/book/node76.html">
              the meta-circular evaluator from SICP
            </a>{" "}
            on top of our system would probably be straightforward, at least the
            purely functional version that doesn't have definitions and
            assignments.
          </li>
          <li>
            We could also pick a simple Universal Turing Machine. Each instance
            of H is responsible for implementing a single step, and so only
            needs access to the current location of the tape. To allow for
            bidirectional navigation, the tape could be encoded as a doubly
            linked list.
          </li>
        </ul>
      </Section>

      <Section
        title="Internal dialog: Pointing to agents and sending follow-up messages"
        slug="internalDialog"
      >
        <p>
          We can separate the act of instantiating an agent from sending
          messages to it. Instantiation creates an agent that hasn’t observed
          any messages yet, and returns a pointer to it:
        </p>
        <WorkspaceElement
          standAlone={true}
          action="Instantiate"
          observation="@1"
        />
        <p>
          When H sends a message to @1, she receives a response and a pointer to
          that agent’s new state, @2:
        </p>
        <WorkspaceElement
          standAlone={true}
          action="@1 What is the size of a football field?"
          observation="@2: An American Football field is 4351 square meters"
        />
        <p>Follow-up messages work the same way as initial messages:</p>
        <WorkspaceElement
          standAlone={true}
          action="@2 What about a soccer field?"
          observation="@3: A soccer field is 7140 square meters"
        />
        <p>
          We treat agents as functional data structures: @1, @2 and @3 all point
          to different versions of the same agent. H can send follow-up messages
          to any of them, as often as she likes. (The command “instantiate”
          doesn’t actually create an agent—it simply returns a pointer to the
          agent that hasn’t received any observations.)
        </p>
        <p>
          Just like pointers to data, pointers to agents can be used in
          messages:
        </p>
        <WorkspaceElement
          standAlone={true}
          action="@1 Let @2 and @3 debate question #1 and return a list of their arguments."
        />
        <p>
          Internal dialog was introduced in{" "}
          <a href="https://ai-alignment.com/strong-hch-bedb0dc08d4e">
            Strong HCH
          </a>
          .
        </p>
        <Subsection title="Issues" toc={false} isLast={true}>
          <h4>Humans are not functional data structures</h4>
          <p>
            We can’t actually store references to a particular human’s state at
            a point in time and resume from that state multiple times. We can
            address this using{" "}
            <a href="#sub-call-strategy">the same strategy</a> we used to
            simulate sub-calls that return immediately: We identify worker
            states with workspace states. We use different humans to handle
            different messages to a single pointer, accept that we lose any
            internal state that the original agent referred to by @1 had, but
            rely on the corresponding workspace providing enough information for
            the different workers to have roughly comparable mental states.
          </p>
          <h4>
            Follow-up messages conflict with the question/body/response
            workspace structure
          </h4>
          <p>
            With follow-up messages, workspaces no longer necessarily have a
            single question and answer. So, the separation into
            question/body/response doesn’t hold up (or would need to be
            repeated). In this case, it seems simpler to treat workspaces as
            sequences of interleaved observations and actions. For example:
          </p>
          <Workspace unstructured={true}>
            <WorkspaceObservation text="@1: What is the size of a football field?" />
            <WorkspaceAction text="Ask What is the length of an American football field?" />
            <WorkspaceObservation text="@2: 109.1m" />
            <WorkspaceAction text="..." />
            <WorkspaceAction
              text={
                <span>
                  Reply An American Football field is 4351m<sup>2</sup>
                </span>
              }
            />
            <WorkspaceObservation text="@3: What about a soccer field?" />
            <WorkspaceAction text="Ask What is the length of a soccer field?" />
            <WorkspaceAction text="..." />
            <WorkspaceAction
              isLast={true}
              text={
                <span>
                  Reply A soccer field is 7140m<sup>2</sup>
                </span>
              }
            />
          </Workspace>
          <p>
            Here, @3 is the name of the parent workspace that asks a follow-up
            question directed at the workspace we're looking at.
          </p>
          <p>
            In the following, I will still often use the question/body/response
            workspace structure, which is simple and appropriate when we don’t
            have internal dialog, and can be adapted when we do.
          </p>
        </Subsection>
      </Section>

      <Section title="Edits: Incremental changes to workspaces" slug="edits">
        <p>
          So far, we have treated workspaces as append-only. For example, this
          is a workspace:
        </p>
        <Workspace question="How many US dimes would it take to fill a bathtub? (#1)">
          <WorkspaceElement
            action="What is the volume of a U.S. dime?"
            observation="0.34 cubic cms"
          />
          <WorkspaceElement
            action="What are a few different ways we could approach the task #1?"
            observation="#2 is a list of 5 approaches."
            isLast={true}
          />
        </Workspace>
        <p>
          At this point, we’d usually append a next action (and next result) to
          the body:
        </p>
        <WorkspaceElement
          standAlone={true}
          action="For each approach in #2, apply it to task #1."
          observation="#3 is a list of 5 results."
        />
        <p>
          Alternatively, our workspaces could support destructive operations
          (edits and deletions). One approach is to use indexed registers:
        </p>
        <Workspace question="How many US dimes would it take to fill a bathtub? (#1)">
          <WorkspaceElement
            register="A"
            action="What is the volume of a U.S. dime?"
            observation="0.34 cubic cms"
          />
          <WorkspaceElement
            register="B"
            action="What are a few different ways we could approach the task #1?"
            observation="#2 is a list of 5 approaches."
            isLast={true}
          />
        </Workspace>
        <p>
          In this case, we can apply the same action as before, but we specify a
          target register (say A). When the call returns, it overwrites the
          information in register A and the workspace looks like this:
        </p>
        <Workspace question="How many US dimes would it take to fill a bathtub? (#1)">
          <WorkspaceElement
            register="A"
            action="For each approach in #2, apply it to task #1."
            observation="#3 is a list of 5 results."
          />
          <WorkspaceElement
            register="B"
            action="What are a few different ways we could approach the task #1?"
            observation="#2 is a list of 5 approaches."
            isLast={true}
          />
        </Workspace>
        <p>
          Instead of registers, we could also treat the workspace body as
          free-form text. In this case, the actions would be more complex, and
          we’d need a story for how sub-calls and their results interact with
          the text. (See also:{" "}
          <a href="#structuredContent">Structured content</a>)
        </p>
        <Subsection title="Issues" toc={false} isLast={true}>
          <h4>
            Edits exacerbate the difference between a single stateful agent and
            its multi-agent simulation
          </h4>
          <p>
            <a href="#sub-call-strategy">Previously</a>, we simulated a single
            agent accumulating state using multiple agents who each had access
            to successive versions of a workspace. Destructive edits increase
            the difference between what the single agent would have done (whose
            state was built up with access to the entire version history of the
            workspace), and what a sequence of agents would do (each of which
            has access only to a single revision). This may not matter—we can
            probably just give up on the idea of H acting over 15 minutes to
            generate multiple actions that ultimately result in a response, and
            directly start with the setting where H has 15 minutes to generate a
            single action that modifies a given workspace.
          </p>
        </Subsection>
      </Section>

      <Section
        title="Persistence: Iterative improvement of workspace trees"
        slug="persistence"
      >
        <p>
          The schemes described so far are all fairly demanding for H. In any
          recursive question-answering scheme, if the human H at the top of the
          tree screws up, the overall answer will be wrong. If one of the
          intermediate Hs returns the wrong response and the workers consuming
          this result are not sufficiently careful, it can affect the
          correctness of the overall response as well. While each H can reduce
          her responsibility by only doing a tiny bit of work and passing on
          most of the task to her descendants, she still faces the challenge to
          get this reduction in responsibility right. If she takes on too much
          work, or otherwise chooses a bad decomposition, and doesn’t
          communicate well to what extent her results can be relied upon, flaws
          can affect the global computation. (See also:{" "}
          <a href="#reflection">Reflection</a>)
        </p>
        <p>
          Edits address this issue to some extent: They reduce H’s
          responsibility to only making a small change to a workspace. However,
          once H is done editing the body and submits a response, the workspace
          effectively terminates, without room for improvement. This makes
          sense, since whoever instantiated this sub-problem can then use the
          response and move on. But it also means that whatever work was done
          before submission is now set in stone.
        </p>
        <p>
          By contrast, consider the setting where all workspaces persist even
          after an initial response has been sent. In this case, we can generate
          an infinite sequence of tasks for each workspace by asking H to make a
          small improvement to a workspace we present to her (or to pass, if she
          doesn’t see a way to improve it). If H adds a new sub-call, we create
          a new workspace as usual. If there was already a response, and H
          changes it, we propagate this change to the parent workspace and mark
          it as stale, i.e. requiring editing by another instance of H. We
          likewise propagate changes to child workspaces when the question text
          of sub-calls gets edited, since these workspaces now have a persistent
          identity independent of the question associated with them.
        </p>
        <p>In this setting, it is natural to start with a “null answer”—</p>
        <Workspace
          question="How many cars are there in the U.S.?"
          response="I don't know"
        />
        <p>
          —and to improve it step by step over time by iterating on notes and
          subcalls in the body and updating the overall response based on their
          outputs, thus growing and adjusting the computation tree. When parts
          of the tree of workspaces get edited, these edits propagate to the
          rest of the tree step by step as we mark dependent structures as
          stale.
        </p>
        <Subsection title="Issues" toc={false} isLast={true}>
          <h4>How do we choose which workspace gets edited next?</h4>
          <p>
            Previously, at each point there was a unique workspace that was
            waiting for an action by H. Now, any workspace can be acted upon,
            and the “stale” markers roughly correspond to the previous notion of
            “waiting for an action”. H can’t make that choice, since we posit
            that each H only ever sees a single workspace. So, we need an
            algorithm that does make that choice.
          </p>
          <p>
            In practice, how we choose what gets edited next might have a big
            impact on how efficient our scheme is. In theory, if what we care
            about is eventual convergence to high-quality responses, it maybe
            good enough to repeatedly loop over all workspaces.
          </p>
          <h4 id="budgetsAndPersistence">
            How do budgets interact with persistence?
          </h4>
          <p>
            It’s not obvious how to generalize <a href="#budgets">budgets</a> to
            this setting. My best guess: Budgets can be used for iterating on
            the current workspace, and can also be passed on to sub-workspaces.
            When a sub-workspace is instantiated, a portion of the parent’s
            budget is assigned to it. This portion starts small, and can be
            increased later. An increase marks the corresponding sub-workspace
            as stale, so that it can be edited to take into account the increase
            in budget. Workspaces can return unused budget to their parents.
          </p>
          <h4>
            How does persistence interact with robustness and error correction?
          </h4>
          <p>
            As we increase the number of calls to H, the probability that one of
            the calls fails goes up. To address this, we need to amplify
            reliability along with capability. Iterative improvement of
            persistent workspace trees seems related—can we make this relation
            more precise?
          </p>
        </Subsection>{" "}
      </Section>

      <Section
        title="Structured content: Organizing workspaces as documents, registers, trees, etc."
        slug="structuredContent"
      >
        <p>
          So far, we have seen a few ideas about what individual workspace
          bodies could look like:
        </p>
        <ul>
          <li>
            A list of H’s actions (view, instantiate, ask) and resulting
            observations
          </li>
          <li>
            Named registers, each of which contains an action and observation
          </li>
        </ul>
        <p>
          This is an axis along which proposals for amplification can vary. Here
          are some other possibilities:
        </p>
        <ul>
          <li>Free-form plain text</li>
          <li>Rich text documents</li>
          <li>
            Tree-structured outlines (as in{" "}
            <a href="https://workflowy.com/">Workflowy</a>)
          </li>
        </ul>
        <p>If we want to include access to external computation:</p>
        <ul>
          <li>Computational scratchpads (think Jupyter notebook)</li>
          <li>Virtual machine states, along with some Desktop environment</li>
        </ul>
        <p>
          For each choice, we want to limit the visible portion to a fixed
          finite size, so that we can treat the policy that H implements as
          finite.
        </p>
      </Section>

      <Section
        title="Reflection: Reifying computation for inspection"
        slug="reflection"
      >
        <p>
          As the tree of questions and answers (or workspaces) grows, one might
          worry that the computation will start to break down for non-local
          reasons. That is, each instance of H is trying to do their best to act
          in the context they see, and none of them take actions that locally
          look like mistakes, but since no one can see the big picture, some
          failures might go unnoticed.
        </p>
        <p>
          To help address this, we can give H access to a command that reifies
          the entire tree of workspaces into a data structure:
        </p>
        <WorkspaceElement
          standAlone={true}
          action="Reflect"
          observation="#1 is a pointer to the computation tree."
        />
        <p>
          H can then instantiate sub-agents that reason about properties of this
          computation.
        </p>
        <WorkspaceElement
          standAlone={true}
          action="How much budget did the sub-computations in #1 spend on what kinds of operations?"
          observation="#2 is a profiler’s view of the computations and budgets in #1."
        />
        <p>Or:</p>
        <WorkspaceElement
          standAlone={true}
          action="Did any explicit search over spaces with more than 10⁶ elements happen as part of #1?"
          observation="No, the largest explicit enumeration was over 220 elements."
        />
        <p>
          If reified computations don’t just include sub-computations, but also
          information about ancestors of the workspace where “reflect” was
          executed, this can replace communication to some extent, as long as
          internal states accumulated by instances of H don’t play a big role
          relative to explicit content. For example, instead of asking a
          follow-up question directed at the parent to clarify what unit would
          be most useful, we can run a sub-computation with access to our
          ancestors:
        </p>
        <WorkspaceElement
          standAlone={true}
          action="What unit would be most useful to return, given that we're in computational context #1?"
          observation={
            <span>
              m<sup>2</sup>
            </span>
          }
        />
        <Subsection title="Issues" toc={false} isLast={true}>
          <h4>How difficult is reasoning about large computations?</h4>
          <p>
            Reasoning about large computations without any individual having
            access to more than a small portion will be challenging, probably
            for the same reasons that make it difficult to solve any complex
            problem by composing local thinking. This may point to a
            bootstrapping issue: If our system could reason about large
            computations, we could use that ability to fix issues that prevent
            us from getting local thinking to solve complex problems; but maybe
            our system can’t successfully implement such reasoning using local
            thinking without already having the ability to reflect.
          </p>
        </Subsection>
      </Section>

      <Section
        title="Meta-execution: Agents made out of agents"
        slug="metaExecution"
      >
        <p>
          If reflection is about the agent-collective understanding one of its
          computations after the fact, then meta-execution is about the
          collective understanding a computation ahead of time through careful
          planning.
        </p>
        <p>
          We have looked at a number of mechanisms that all make calls to H.
          I’ll use M<sup>H</sup> to refer to one such mechanism. Each call to H
          includes some context (e.g., a question) and H returns an action
          (e.g., a sub-question to ask) that seems good to her in this context.
          We don’t know how H works—she is a human, her brain is a black box to
          us.
        </p>
        <p>
          In meta-execution (introduced{" "}
          <a href="https://ai-alignment.com/meta-execution-27ba9b34d377">
            here
          </a>
          ), we replace H with an implementation X that we will provide
          ourselves. Let’s call the resulting mechanism M<sup>X</sup>. If we
          want to solve object-level tasks such as “Read this book and tell me
          why x did y.” we present them to M<sup>X</sup>.
        </p>
        <p>
          In the process of solving such tasks, M<sup>X</sup> will quickly
          encounter sub-tasks that it would have presented to H, and will
          present them to our implementation X instead. Here’s how X works: We
          create a new cognitive task that looks like this...
        </p>
        <WorkspaceElement
          standAlone={true}
          action="What action should we take in context #1?"
        />
        <p>
          ...and present it to M<sup>H</sup>. Here, #1 is a data structure with
          information about the current sub-task, but this context is now hidden
          behind pointers. For example, it might look like this:
        </p>
        <pre>
          #1 = “The question that starts with word #2 and continues with words
          #3”
          <br />
          #2 = “The word that starts with letter #4 and continues with letters
          #5”
          <br />
          #4 = “R”
          <br />
          #5 = “The list that starts with letter #6 and continues with letters
          #7”
          <br />
          #6 = “e”
          <br />
          ...
        </pre>
        <p>
          Any actions that don’t look at the entire data structure will be
          applicable across multiple contexts (see:{" "}
          <a href="#caching">Caching</a>), and if looking at the value of the
          data structure would have caused H to malfunction, we may be able to
          avoid this by analyzing it piecemeal through MH.
        </p>
        <p>
          In short, meta-execution replaces M<sup>H</sup> with M
          <sup>
            M<sup>H</sup>
          </sup>
          . Our mechanism still bottoms out at H, but we have introduced a level
          of indirection, so that H chooses meta-level actions, and only
          indirectly contributes to solving the object-level task.
        </p>
        <p>
          Previously, we could think about the collection of Hs as operating on
          a tree of workspaces, with each H responsible for a single change to a
          single workspace. Now each change itself corresponds to a tree of
          workspaces that generates that change.
        </p>
        <p>
          With minor modification, we can also use meta-execution to simulate
          stateful agents (e.g. if we want to create an agent that maintains
          state over multiple actions in a workspace). In that case, the context
          includes the agent’s state, and the returned action includes an
          updated state.
        </p>
      </Section>

      <Section
        title="Interaction: Responding to a stateful world"
        slug="interaction"
      >
        <p>
          So far, we have assumed that the problem that our mechanisms are
          solving is given as a single question. Through the use of pointers,
          question and response can be large, but they were taken to be fully
          specified in some sense, with no way to query for more information. We
          assumed that the only interaction between the system and the real
          world is through the final response it returns.
        </p>
        <p>
          How could we enable back-and-forth interaction between our mechanism
          and the real world?
        </p>
        <Subsection title="Types of interactions" toc={false}>
          <p>
            For some kinds of interactions, we can treat the world as
            functional. For queries to Google and Wikipedia, this is probably
            acceptable in cases where the returned information changes slowly
            relative to the time scale on which the problem is solved. In this
            case, we can simply provide H with access to a function that
            retrieves the relevant information.
          </p>
          <p>
            For other interactions, we really do need to treat the world as
            stateful, changing with each interaction. If the system holds a
            conversation with a user, we need to take into account that each
            message changes the situation substantially. If H interacts with a
            computer that maintains state for more than 15 minutes, this is the
            case as well. In this setting, we can’t give each H direct access to
            the world, since their interactions would interfere with each other.
            We can’t simply introduce a pointer to the user (or computer) and
            approach it the same way we approach{" "}
            <a href="#internalDialog">dialog with internal agents</a>, since we
            treat such agents as purely functional data structures.
          </p>
        </Subsection>
        <Subsection
          title="Interaction through resource pointers"
          slug="resourcePointers"
          toc={false}
        >
          <p>
            A first idea is to use internal dialog, but to introduce a new type
            of pointer &1 that cannot be copied. When it is passed to a
            sub-call, the H that originally had access to it loses that access.
            When a message is sent to &1, the pointer is “used up” and no
            further messages can be sent to it. When &1 replies, it returns a
            new resource pointer &2 along with its response.
          </p>
        </Subsection>
        <Subsection
          title="Interaction through indirection"
          slug="indirection"
          toc={false}
          isLast={true}
        >
          <p>
            Alternatively, we can apply indirection, similar to the
            meta-execution indirection. The task we present to the system
            includes a pointer to all observations so far:
          </p>
          <WorkspaceElement
            standAlone={true}
            action="Given that #1 is a transcript of the dialog so far, what message should we send next to the user?"
          />
          <p>
            Once the system returns an answer, we create a new top-level task of
            the same form and re-run the entire computation. Since the
            observations behind the pointer changed, computations that accessed
            it may now behave differently, and the overall response may be
            different as well. We thus generate the dialog with the user step by
            step through a sequence of tasks. We can also pass state between
            these tasks if necessary by reformulating the task as follows:
          </p>
          <WorkspaceElement
            standAlone={true}
            action="Given that #1 is a transcript of the dialog so far and that we’re in state #2, what message should we send next to the user and what state should we end up in?"
          />
          <p>
            If we use <a href="#persistence">persistent workspaces</a> and the
            corresponding propagation machinery, we can make rerunning the
            computations more tractable: We treat changes to observations of
            external state just like we treat other edits. That is, we track
            what sub-computations depend on the new observations and mark them
            as stale. If the context changed only a little bit, we can
            approximately reuse a lot of computation this way and thus reduce
            the number of calls to H.
          </p>
          <p>
            From a computational point of view, interaction via indirection and
            persistence might recover a version of{" "}
            <a href="http://www.umut-acar.org/self-adjusting-computation">
              self-adjusting computation
            </a>
            .
          </p>
        </Subsection>
      </Section>

      <Section title="Out of scope">
        <p>
          There are additional mechanisms that will matter a lot for practical
          implementations, but that are out of scope if we are primarily
          concerned with what capabilities we can theoretically reach by
          amplifying human thinking, and don’t care about reducing the number of
          calls to H:
        </p>
        <Subsection title="Caching" toc={false}>
          <p>
            Mechanisms that make calls to H can eliminate redundant work by
            memoizing H. That is, we save all responses from H for the
            corresponding inputs, and whenever we would usually call H, we first
            check whether there is already a response in the cache table. If so,
            we use that response instead of calling H.
          </p>
          <p>
            This is especially powerful in combination with abstraction (
            <a href="#pointers">pointers</a>). If the content behind a pointer
            hasn’t been revealed yet, and so H chose their action without
            knowledge of that content, we can reuse their response across all
            values that could be behind the pointer.
          </p>
          <p>
            For example, this makes it possible to learn the high-level
            structure of an algorithm for computing the length of a list by
            caching the actions that generate these two workspaces:
          </p>
          <Workspace question="What is the length of list #1?" response="#4">
            <WorkspaceElement action="Is #1 empty?" observation="No" />
            <WorkspaceElement
              action="What is the remainder of list #1?"
              observation="#2"
            />
            <WorkspaceElement
              action="What is the length of list #2?"
              observation="#3"
            />
            <WorkspaceElement
              action="What is 1 + #3?"
              observation="#4"
              isLast={true}
            />
          </Workspace>

          <Workspace question="What is the length of list #1?" response="0">
            <WorkspaceElement
              action="Is #1 empty?"
              observation="Yes"
              isLast={true}
            />
          </Workspace>
        </Subsection>

        <Subsection title="Automation using ML" toc={false}>
          <p>
            Beyond caching, we can consider more sophisticated automation
            strategies. For example, we could introduce automation to decide
            when to hide values behind pointers, or automation that learns when
            two natural-language questions are likely to be semantically
            identical.
          </p>
        </Subsection>

        <Subsection title="Aggregation & incentives" toc={false}>
          <p>
            Real implementations need to divide up work between humans whose
            background knowledge, motivation, and abilities differ. One approach
            to incentivizing and evaluating their work is the one taken in
            dialog markets: occasionally evaluate in depth by solving the task
            “What should the reward for contribution #1 be?” using our system,
            and learn to predict the outcome of such deep evaluations from
            cheaper signals.
          </p>
        </Subsection>

        <Subsection title="Serial vs. parallel execution" toc={false}>
          <p>
            To run tasks that require a large number of human work hours, we
            want to parallelize where possible, so that different humans can
            simultaneously work on different parts of the same global task.
          </p>
        </Subsection>

        <Subsection title="Eager vs. lazy evaluation" toc={false} isLast={true}>
          <p>
            We might be able to reduce the amount of human work by delaying
            sub-tasks until they are needed to generate the overall return value
            in as much detail as its consumer requires.
          </p>
        </Subsection>
      </Section>
      <Section title="Existing systems">
        <p>
          The table below is a rough attempt to relate the mechanisms above to
          some existing and proposed systems. Many have key features that are
          not captured by the mechanisms discussed above, especially multi-user
          features, so this is an extremely lossy projection.
        </p>
        <TableOfMechanisms />
        <p>Hover over shaded cells to reveal clarifying information.</p>
      </Section>
    </GridLayout>
  </Page>
);

export default Taxonomy;
