// @flow

import * as React from "react";
import classNames from "classnames";
import Tooltip from "react-simple-tooltip";
import { Checkbox } from "react-bootstrap";

import type { ColumnData, RowData, CellData } from "./types";
import { columns, rows } from "./data";

import "./TableOfMechanisms.css";

const cellClassNameFromContent = (content: CellData, extra?: Object) => {
  const isObject = content && typeof content === "object";
  return classNames(
    // $FlowFixMe
    isObject && content.className,
    {
      // $FlowFixMe
      "Table-Cell-HasComment": isObject && content.comment
    },
    extra
  );
};

const Column = (props: { children?: React.Node }) => (
  <th className="rotate">
    <div>
      <span>{props.children}</span>
    </div>
  </th>
);

const Row = (props: { row: RowData }) => {
  const row = props.row;
  return (
    <tr>
      <th
        className={cellClassNameFromContent(row.title, { "row-header": true })}
      >
        <CellValue content={row.title} />
      </th>
      {columns.map(column => (
        <Cell
          key={`${column.accessor}-${row.name}`}
          row={row}
          column={column}
        />
      ))}
    </tr>
  );
};

const Cell = (props: { row: RowData, column: ColumnData }) => {
  const row = props.row;
  const column = props.column;
  const content = row[column.accessor];
  return (
    <td className={cellClassNameFromContent(content)}>
      <CellValue content={content} />
    </td>
  );
};

const CellValue = (props: { content: CellData }) => {
  let { content } = props;
  let value = null;
  let comment = null;
  if (content && typeof content === "object" && content.comment) {
    // $FlowFixMe
    comment = content.comment;
    // $FlowFixMe
    value = content.value;
  } else {
    value = content;
  }
  let node = null;
  if (React.isValidElement(value)) {
    // $FlowFixMe
    node = <div>{value}</div>;
  } else if (typeof value === "string") {
    node = <span>{value}</span>;
  } else {
    node = <Checkbox checked={value} readOnly />;
  }
  if (comment) {
    return (
      <Tooltip
        className="Table-Cell-Tooltip"
        // $FlowFixMe
        content={<div className="Table-Cell-Tooltip-Text">{comment}</div>}
      >
        {node}
      </Tooltip>
    );
  } else {
    return node;
  }
};

const TableOfMechanisms = () => (
  <div className="Table-Container">
    <table className="table table-header-rotated">
      <thead>
        <tr>
          <th />
          {columns.map(column => (
            <Column key={column.accessor}>
              <a href={`#${column.accessor}`}>{column.title}</a>
            </Column>
          ))}
        </tr>
      </thead>
      <tbody>{rows.map(row => <Row key={row.name} row={row} />)}</tbody>
    </table>
  </div>
);

export default TableOfMechanisms;
