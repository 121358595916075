// @flow

import * as React from "react";

import "./LocalNavigation.css";

type LocalNavigationProps = {
  children?: React.Node,
  style?: Object,
  id?: string
};

const LocalNavigation = (props: LocalNavigationProps) => (
  <div className="LocalNavigation" style={props.style} id={props.id}>
    {props.children}
  </div>
);

export default LocalNavigation;
