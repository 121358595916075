// @flow

import _ from "lodash";
import slug from "slug";
import posts from "./posts";

const postUrlToName = _.fromPairs(
  posts.slugs.map((slug) => [`/updates/${slug}`, posts.title(slug)])
);

const urlToName = _.assign(
  {
    "/": "Home",
    "/about": "About",
    "/careers": "Careers",
    "/careers/software-engineer": "Full-Stack Engineer",
    "/careers/frontend-engineer": "Frontend Engineer",
    "/careers/product-manager": "Product Manager",
    "/careers/general": "General Interest",
    "/donate": "Donate",
    "/elicit": "Elicit",
    "/mission": "Mission",
    "/participate": "Participate",
    "/presentations": "Presentations",
    "/presentations/delegating-cognitive-work-2019-06":
      "Delegating Cognitive Work",
    "/presentations/factored-cognition-2018-05":
      "Factored Cognition (May 2018)",
    "/research": "Research",
    "/research/dialog-markets": "Dialog Markets",
    "/research/dialog-markets/dalca": "DALCA",
    "/research/dialog-markets/microtasks": "Markets for microtasks",
    "/research/dialog-markets/overview": "Overview",
    "/research/factored-cognition": "Factored Cognition",
    "/research/factored-cognition/networks-of-workspaces":
      "Networks of Workspaces",
    "/research/factored-cognition/scalability": "Scalability",
    "/research/factored-cognition/tasks": "Tasks",
    "/research/factored-cognition/taxonomy": "Taxonomy",
    "/research/judgments": "Predicting Slow Judgments",
    "/team": "Team",
    "/updates": "Updates",
  },
  postUrlToName
);

export function findRouteName(url: string) {
  return urlToName[url];
}

export function internalLinkSlug(title: string, fixedSlug: ?string) {
  return fixedSlug || slug(title, { lower: true });
}

export function internalLinkURL(title: string, fixedSlug: ?string) {
  return `#${internalLinkSlug(title, fixedSlug)}`;
}
