// @flow

import { CoinVertical } from "@phosphor-icons/react";
import * as React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import MetaHeader from "../../components/MetaHeader/MetaHeader";
import NewsletterForm from "../../components/NewsletterForm/NewsletterForm";
import posts from "../../posts";
import "./Home.css";

const BlogPosts = () => {
  return (
    <section className="section blog">
      <div className="container">
        <p className="eyebrow">Latest updates</p>
        {posts.slugs.slice(0, 1).map((slug) => {
          const description = posts.data.get(slug).header.description;
          return (
            <div className="FeaturePost" key={slug}>
              <Link to={`/updates/${slug}`}>
                <div>
                  <h2>{posts.title(slug)}</h2>
                  {description !== undefined && <p>{description}</p>}
                </div>
              </Link>
            </div>
          );
        })}

        <div className="SecondaryPosts">
          {posts.slugs.slice(1, 4).map((slug) => {
            const description = posts.data.get(slug).header.description;
            return (
              <Link
                className="SecondaryPostItem"
                to={`/updates/${slug}`}
                key={slug}
              >
                <div key={slug}>
                  <div>
                    <h3>{posts.title(slug)}</h3>
                    {description !== undefined && <p>{description}</p>}
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </section>
  );
};

const Donate = () => {
  return (
    <section className="section donate">
      <div className="container">
        <div className="LeftDonate">
          <CoinVertical size={64} />
          <div>
            <h2>Donate</h2>
            <p>
              We're a non-profit with 501(c) status. You can donate to support
              Ought's work on AI alignment and scaling up good reasoning.
            </p>
          </div>
        </div>
        <Link to="/donate">
          <div className="DonateButton">Donate -></div>
        </Link>
      </div>
    </section>
  );
};

const Home = () => {
  return (
    <div className="home-container">
      <MetaHeader
        title="Ought"
        description="Ought is a product-driven research lab that develops mechanisms for delegating high-quality reasoning to advanced machine learning systems."
      />
      <div className="HomeBanner">
        <div className="BannerContent">
          <div>
            <h1 className="BannerHeading">Scale up good reasoning</h1>
            <p>
              Machine learning will transform any area of life that has abundant
              data and easily measurable objectives.{" "}
              <span className="highlight">But will it make us wiser?</span>
            </p>

            <p>
              Ought is a product-driven research lab that develops mechanisms
              for delegating high-quality reasoning to advanced machine learning
              systems.
            </p>
            <Link to="/mission">
              <div className="Home-Banner-More">Read our mission -></div>
            </Link>
          </div>
        </div>
      </div>
      <BlogPosts />
      <Donate />
      <NewsletterForm />
      <Footer />
    </div>
  );
};

export default Home;
