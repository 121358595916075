// @flow

import _ from "lodash";
import * as React from "react";

import TableOfContents from "../../components/TableOfContents/TableOfContents";
import { internalLinkSlug } from "../../routes";

import "./Presentation.css";

type PresentationProps = {
  children: React.Node,
  skip?: Array<number>,
  include?: Array<number>,
  minIndex: number,
  maxIndex: number,
  imageURL: (index: number) => string,
  annotations: { [key: string]: string | React.Node },
  titles: { [key: string]: string | React.Node },
  showSlideNumbers: boolean,
  toc: boolean,
};

const Presentation = (props: PresentationProps) => {
  const slides = _.range(props.minIndex, props.maxIndex).map((index) => {
    const title = props.titles[index];
    const includeSlide = props.skip
      ? !_.includes(props.skip, index)
      : _.includes(props.include, index);
    return (
      includeSlide && (
        <Slide
          index={index}
          title={title}
          toc={true}
          annotation={props.annotations[index]}
          showNumber={props.showSlideNumbers}
          imageURL={props.imageURL(index)}
        />
      )
    );
  });
  return (
    <div className="Presentation">
      <div className="Presentation-Summary">{props.children}</div>
      {props.toc && <TableOfContents id="toc">{slides}</TableOfContents>}
      {slides}
    </div>
  );
};

type SlideProps = {
  imageURL: string,
  annotation: string,
  index: number,
  showNumber: boolean,
  toc: boolean,
  title?: string,
};

const Slide = (props: SlideProps) => (
  <div key={props.index}>
    {props.title && (
      <h2 className="Slide-Title" id={internalLinkSlug(props.title)}>
        {props.title}
      </h2>
    )}
    <div className="Slide" id={props.index}>
      {props.showNumber && (
        <div className="Slide-Number">
          <a href={`#${props.index}`}>{props.index}</a>
        </div>
      )}
      <div className="Slide-Image-Container">
        <a className="Slide-Image-Link" href={props.imageURL}>
          <img
            alt={`Slide ${props.index}`}
            className="Slide-Image"
            src={props.imageURL}
          />
        </a>
      </div>
      <div className="Slide-Annotation">{props.annotation}</div>
    </div>
  </div>
);

export default Presentation;
