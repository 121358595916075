// @flow

import * as React from "react";
import { Link } from "react-router-dom";

import "./Brand.css";

const Brand = () => {
  return (
    <div className="Brand">
      <Link className="Brand-Link" to="/">
        ought
      </Link>
    </div>
  );
};

export default Brand;
