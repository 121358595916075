// @flow

import * as React from "react";
import GridLayout from "../../components/GridLayout/GridLayout";
import NewsletterForm from "../../components/NewsletterForm/NewsletterForm";
import Page from "../../components/Page/Page";
import Person from "../../components/Person/Person";
import "./Team.css";

const Team = () => {
  return (
    <div>
      <Page className="Team" title="Team" next={null}>
        <GridLayout>
          <h2 className="bodTitle">Board of Directors</h2>
          <div className="bod">
            <Person
              name="Owain Evans"
              position="Director & CEO"
              imageURL="/images/profiles/evans.jpg"
              url="https://owainevans.github.io/"
              bio={
                <span>
                  Owain runs a research group on AI Safety in Berkeley. He
                  previously worked as a research scientist at the University of
                  Oxford and at Ought (2019-20). He holds a Ph.D. from MIT.
                </span>
              }
            />
            <Person
              name="Lukas Finnveden"
              position="Director"
              imageURL="/images/profiles/finnveden.png"
              url="https://lukasfinnveden.substack.com/"
              bio={
                <span>
                  Lukas is a research fellow at Open Philanthropy, focusing on
                  what we can do to reduce potential risks from advanced AI.
                  Previously, he was a research scholar at the University of
                  Oxford’s Future of Humanity Institute. He has a B.S. in
                  computer science from KTH Royal Institute of Technology.
                </span>
              }
            />
            <Person
              name="Owen Cotton-Barratt"
              position="Director"
              imageURL="/images/profiles/owen.jpg"
              url="https://www.csap.cam.ac.uk/network/owen-cotton-barratt/"
              bio={
                <span>
                  Owen is a researcher who has spent the last ten years on
                  questions around what is best to do given uncertainty about
                  the future of AI. Owen holds a DPhil in mathematics from the
                  University of Oxford.
                </span>
              }
            />
          </div>

          <h2 className="bodTitle">Elicit</h2>
          <p>
            The team of <a href="https://elicit.com">Elicit</a>, a public
            benefit corporation incubated at Ought, includes many former Ought
            employees, including:
          </p>
          <ul>
            <li>
              <a href="https://stuhlmueller.org/">Andreas Stuhlmüller</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/jungwonbyun/">
                Jungwon Byun
              </a>
            </li>
            <li>
              <a href="https://jmsbrdy.com/">James Brady</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/brachbach/">Ben Rachbach</a>
            </li>
            <li>
              <a href="https://www.justinreppert.com/">Justin Reppert</a>
            </li>
            <li>
              <a href="https://lukestebbing.com/">Luke Stebbing</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/charlie-george-2868a0235/">
                Charlie George
              </a>
            </li>
            <li>
              <a href="https://maggieappleton.com">Maggie Appleton</a>
            </li>
            <li>
              <a href="https://vivalapanda.moe/">Adrian "Panda" Smith</a>
            </li>
            <li>
              <a href="https://www.sarahpark.co/">Sarah Park</a>
            </li>
          </ul>
          <p className="final">
            Read{" "}
            <a href="https://elicit.com/team">more about the Elicit team</a>.
          </p>
        </GridLayout>
      </Page>

      <NewsletterForm />
    </div>
  );
};

export default Team;
