// @flow

import * as React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import About from "../../pages/About/About";
import Blog from "../../pages/Blog/Blog";
import Careers from "../../pages/Careers/Careers";
import Donate from "../../pages/Donate/Donate";
import Elicit from "../../pages/Elicit/Elicit";
import Home from "../../pages/Home/Home";
import Mission from "../../pages/Mission/Mission";
import Participate from "../../pages/Participate/Participate";
import Presentations from "../../pages/Presentations/Presentations";
import Research from "../../pages/Research/Research";
import Team from "../../pages/Team/Team";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import NotFound from "../NotFound/NotFound";
import "./App.css";

const App = () => {
  return (
    <div className="App">
      <div className="App-Main">
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/mission" component={Mission} />
          <Route exact path="/team" component={Team} />
          <Route exact path="/about" component={About} />
          <Route exact path="/participate" component={Participate} />
          <Route path="/updates" component={Blog} />
          <Route path="/research" component={Research} />
          <Route path="/presentations" component={Presentations} />
          <Route path="/careers" component={Careers} />
          <Route path="/donate" component={Donate} />
          <Route path="/elicit" component={Elicit} />
          <Route component={NotFound} />
        </Switch>
      </div>
      <Switch>
        <Route exact path="/" />
        <Route component={Footer} />
      </Switch>
    </div>
  );
};

export default withRouter(App);
