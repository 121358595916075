// @flow

import * as React from "react";

import ImageItem from "../ImageItem/ImageItem";

type Props = {
  url: string,
  title: string,
  description: string,
  imageURL: string,
  imageStyle?: Object
};

const Project = (props: Props) => (
  <ImageItem
    title={props.title}
    imageURL={props.imageURL}
    url={props.url}
    body={props.description}
    imageStyle={props.imageStyle}
  />
);

export default Project;
