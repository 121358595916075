// @flow

import React from "react";
import { Link } from "react-router-dom";
import Script from "react-load-script";
import Page from "../../components/Page/Page";
import GridLayout from "../../components/GridLayout/GridLayout";
import NewsletterForm from "../../components/NewsletterForm/NewsletterForm";
import "./Donate.css";

const Donate = () => {
  return (
    <div>
      <Page className="Donate" title="Donate">
        <GridLayout>
          <h2>About Ought</h2>
          <p>
            Ought is a non-profit research lab whose mission is to automate and
            scale open-ended reasoning. Ought is building{" "}
            <a href="https://elicit.org/">Elicit</a>, a research assistant using
            language models to automate parts of researchers' workflows. For
            more information about Ought's vision, please see{" "}
            <Link to="/updates/2022-04-06-process">
              Supervise Process, not Outcomes
            </Link>{" "}
            and{" "}
            <Link to="/updates/2022-04-08-elicit-plan">
              The Plan for Elicit
            </Link>
            .
          </p>
          <p>
            We are a 501(c)(3) charitable organization. Our tax ID number is
            81-2121304. All contributions are tax-deductible in the United
            States.
          </p>
          <h2>Digital Currency</h2>
          <p>
            We accept donations in Bitcoin, Ethereum, Bitcoin Cash, Litecoin,
            Dai, and USD Coin.
          </p>
          <div>
            <a
              id="donate-with-crypto"
              className="donate-with-crypto"
              href="https://commerce.coinbase.com/checkout/c56fdc53-f8d7-41ef-b53c-1c469ad95818"
            >
              Donate BTC, ETH, BCH, LTC, DAI, or USDC
            </a>
            <script src="https://commerce.coinbase.com/v1/checkout.js?version=201807"></script>
          </div>
          <p>
            If you don't need tax receipts you can also{" "}
            <a href="https://commerce.coinbase.com/checkout/bc6d623e-3365-4566-b734-b39636e79139">
              donate anonymously
            </a>
            .
          </p>
          <h2>Credit Card / PayPal / Bank Transfer</h2>
          <p>
            If you are based in the U.S., bank transfer is our preferred method
            (lowest fees).
          </p>
          <Script url="https://donorbox.org/widget.js" paypalExpress="true" />
          <iframe
            src="https://donorbox.org/embed/ought-org"
            height="685px"
            width="100%"
            style={{
              maxWidth: "400px",
              minWidth: "310px",
              maxHeight: "none!important",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "30px",
              display: "block",
            }}
            seamless="seamless"
            name="donorbox"
            frameBorder="0"
            scrolling="no"
            title="Donate now"
            className="donor-box-frame"
          />
          <h2>Checks / Large Donations</h2>
          <p>
            If you want to donate by check or wire transfer, or donate more than
            $10,000, please contact us at{" "}
            <a href="mailto:operations@ought.org">operations@ought.org</a>.
          </p>
        </GridLayout>
      </Page>
      <NewsletterForm />
    </div>
  );
};

export default Donate;
