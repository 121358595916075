// @flow

import * as React from "react";
import { Link } from "react-router-dom";

import LocalNavigation from "../LocalNavigation/LocalNavigation";
import { ArrowCircleRight } from "@phosphor-icons/react";

import "./Next.css";

export type NextProps = {
  url: string,
  title: string,
  description?: string,
  label?: string,
};

const Next = (props: NextProps) => (
  <LocalNavigation>
    <div className="Next">
      <Link to={props.url}>
        <div className="InnerContainer">
          <div>
            {props.label || <span className="ReadNext">Read next</span>}
            <span className="NextTitle">
              {props.title} {props.description && `– ${props.description}`}
            </span>
          </div>
          <ArrowCircleRight size={48} weight="regular" />
        </div>
      </Link>
    </div>
  </LocalNavigation>
);

export default Next;
