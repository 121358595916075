// @flow

import * as React from "react";

import Article from "../../components/Article/Article";
import MetaHeader from "../../components/MetaHeader/MetaHeader";
import Next from "../../components/Next/Next";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount/ScrollToTopOnMount";
import NewsletterForm from "../../components/NewsletterForm/NewsletterForm";
import posts from "../../posts";

import type { NextProps } from "../../components/Next/Next";
import type { Post } from "../../posts";

import "./Post.css";
import "../../components/LocalNavigation/LocalNavigation.css"; // Used directly in TOC
import "../../components/TableOfContents/TableOfContents.css"; // Used directly in TOC

function nextBlogPage(slug: string): NextProps {
  const post = posts.data.get(slug);
  const defaultNext = {
    url: "/mission",
    title: "Our Mission",
    description: "What we're aiming for",
  };
  if (!post) {
    console.warn(`Couldn't find post for slug ${slug}`);
    return defaultNext;
  }
  if (post.nextSlug) {
    return {
      url: `/updates/${post.nextSlug}`,
      title: posts.title(post.nextSlug),
    };
  }
  return defaultNext;
}

const BlogPostBody = ({ content, title, author, date, summary }: string) => {
  return (
    <div className="BlogPostBodyContainer">
      <div className="BlogMetadata">
        {title && <h1 className="Page-Title">{title}</h1>}
        {author | date && <BlogPostAttribution author={author} date={date} />}
        {summary && (
          <div className="SummaryContainer">
            <p>Summary</p>
            <div
              className="BlogSummary"
              dangerouslySetInnerHTML={{ __html: summary }}
            />
          </div>
        )}
      </div>
      <div
        className="BlogPostBody"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};

const BlogPostAttribution = ({ date, author }) => (
  <div className="BlogPostPage-Attribution">
    <span>
      By{" "}
      <a className="BlogPostPage-Author" href={`mailto:${author.email}`}>
        {author.name}
      </a>
    </span>
    <div className="divider" />
    <span className="BlogPostPage-Date">{date.format("MMMM D, YYYY")}</span>
  </div>
);

const BlogPostArticle = ({
  summary,
  __toc,
  __content,
  author,
  date,
  slug,
  title,
}: Post) => {
  return (
    <Article
      summary={<div dangerouslySetInnerHTML={{ __html: summary }} />}
      tocHTML={__toc}
      short={!summary}
    >
      <BlogPostBody
        author={author}
        date={date}
        title={title}
        content={__content}
        summary={summary}
      />
    </Article>
  );
};

const BlogPostPage = (post: Post) => () => {
  const { title, slug, header } = post;
  const nextPost = nextBlogPage(slug);
  return (
    <section className="BlogPostPage">
      <MetaHeader title={title ? `${title} | Ought` : "Ought"} {...header} />
      <ScrollToTopOnMount />
      <BlogPostArticle {...post} />
      {nextPost && <Next {...nextPost} />}
      <NewsletterForm />
    </section>
  );
};

export { BlogPostArticle, BlogPostPage, BlogPostBody };
