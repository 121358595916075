// @flow

import { Envelope } from "@phosphor-icons/react";
import * as React from "react";
import { Button, FormControl } from "react-bootstrap";
import "./NewsletterForm.css";

const NewsletterForm = () => (
  <div className="NewsletterForm">
    <div className="NewsletterContainer">
      <div className="Left">
        <Envelope size={40} weight="duotone" fill="var(--blue)" />
        <h2>Sign up to our newsletter</h2>
      </div>
      <hr />
      <form
        action="//market.us1.list-manage.com/subscribe/post?u=d2c05aa37b53be5971943f880&amp;id=0e4f32fd79"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        noValidate
      >
        <FormControl
          label="Email address"
          placeholder="Enter email"
          name="EMAIL"
          required
        />
        <div style={{ display: "none" }} aria-hidden="true">
          <input
            type="text"
            name="b_d2c05aa37b53be5971943f880_0e4f32fd79"
            tabIndex="-1"
            value=""
            readOnly={true}
          />
        </div>
        <div className="NewsletterForm-Submit">
          <Button type="submit" value="subscribe" name="subscribe">
            Join
          </Button>
        </div>
      </form>
    </div>
  </div>
);

export default NewsletterForm;
