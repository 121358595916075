// @flow

import * as React from "react";
import GridLayout from "../../components/GridLayout/GridLayout";
import NewsletterForm from "../../components/NewsletterForm/NewsletterForm";
import Page from "../../components/Page/Page";
import Section from "../../components/Section/Section";
import "./Mission.css";

const Mission = () => {
  return (
    <div>
      <Page
        className="Mission"
        title="Our Mission"
        next={{
          url: "/elicit",
          title: "Elicit",
          description: "The AI research assistant",
        }}
      >
        <GridLayout>
          <p>
            Our mission is to{" "}
            <span className="highlight">scale up good reasoning</span>. We want
            ML to help as much with thinking and reflection as it does with
            tasks that have clear short-term outcomes.
          </p>
          <Section title="Likely impacts of machine learning">
            <p>
              In the near future, ML has the potential to reshape any area of
              life where we have clear metrics and plenty of data, probably
              mostly for the better:
            </p>
            <ul>
              <li>
                We’ll see better automated traders, better recommendations for
                what to read or watch, as well as high-quality automated
                translation and text-to-speech transcription.
              </li>
              <li>
                We’ll build control systems that require sophisticated world
                models, including self-driving cars, autonomous drones, and
                advanced industrial robots; but also systems that require
                detailed models of human behavior, such as apps that find
                interventions that make us happier and healthier, help us learn
                more effectively, and “help” us decide what stuff to buy.
              </li>
              <li>
                We’ll have better tools for semi-automated programming and
                engineering, and for generating visual, audio and textual
                content.
              </li>
            </ul>
          </Section>
          <Section title="Where might machine learning not help by default?">
            <p>
              In matters of thought, it is less clear that ML can be of
              substantial help:
            </p>
            <ul>
              <li>
                Can it improve our understanding of the world? Can it help us
                see more clearly why some cities and countries prosper while
                others fail, why humans age, or how a bicycle works?
              </li>
              <li>
                As individuals, can it help us reflect on our personal goals and
                how to achieve them in ways that take into account our flaws and
                limitations? Can it help us become the kinds of people we want
                to be?
              </li>
              <li>
                As a society, can it help us make progress on the problems that
                we collectively face, such as efficiently aiding those most in
                need or coordinating the wishes of different groups?
              </li>
            </ul>
            <p>
              To a limited extent, the answer is “yes” for all of these. ML can
              reveal patterns in arbitrary data, including data relevant to
              these questions, so we should expect some support; and there are
              indirect effects such as automation expanding our leisure time and
              making our society run more smoothly.
            </p>
            <p>
              But will ML help <em>substantially</em> in matters of thought,
              reflection, and understanding? Will it help to a degree that
              rivals the way we expect it to help with driving cars, and beyond,
              ultimately resulting in superhuman performance? So far, there is
              little evidence that it will.
            </p>
          </Section>
          <Section title="Why might machine learning fail to help us think?">
            <p>
              ML works best in domains where we have well-defined tasks (play a
              game of Go) with plenty of existing data (train on recorded human
              games) and clear objectives (win the game). None of these are
              obviously present for supporting thinking:
            </p>
            <ul>
              <li>
                What is the <strong>task</strong>? How do you structure the
                interaction between a human and an ML system if the goal is to
                support the human’s thinking?
              </li>
              <li>
                What is the <strong>data</strong>? Thinking happens mostly in
                our minds. We can record inputs and outputs, but what happens in
                between is more complex than current ML algorithms can infer.
              </li>
              <li>
                What is the <strong>objective</strong>? We don’t care about
                thinking for its own sake, but because it helps us form plans or
                understand ideas that lead to better outcomes. This leads to
                sparse, delayed rewards, a setting that current ML algorithms
                are not suitable for.
              </li>
            </ul>
          </Section>
          <Section title="Why it matters">
            <p>
              Why should we want to get better at thinking in the first place?
              Here’s a partial list of reasons:
            </p>
            <p>
              In the <strong>short term</strong>, many of the issues we care
              about the most—“How can I be healthier?” “How can I find a
              romantic partner?” “What should I do with my life?” “How can I
              make more money?” — are seriously constrained by our ability to
              gather the right information, think through relevant
              considerations, and make robust plans. We can sometimes get help
              from experts, but experts don’t exist for all topics, tend to be
              expensive, and have limited time and ability to deeply consider
              individual situations.
            </p>
            <p>
              In the <strong>medium term</strong>, going beyond issues that can
              be addressed by helping individuals, we could probably make
              progress on many conflicts in our society if we better understood
              our own and others’ values, the space of potential policies, and
              their likely consequences. While some conflicts may be
              unavoidable, it seems plausible that there is lot of zero-sum
              competition that could be replaced with collaboration if all sides
              could see how. There’s a positive vision of the future where
              broadly accessible cognitive technology is the tide that lifts all
              boats and allows us to make considered decisions together.
            </p>
            <p>
              In the <strong>long term</strong>, it’s difficult to see a path
              where we make good use of our{" "}
              <a href="https://80000hours.org/2013/10/influencing-the-far-future/">
                cosmic endowment
              </a>
               — the long future ahead, the vast space available — without
              figuring out how to leverage AI to get better at reflecting about
              what we want things to be like, and how to get there. There may
              always remain some fundamental uncertainty about what we truly
              value the most, but it does seem that not all of our value
              judgments are created equal. Reflecting on our options and plans
              does tend to help. If the space of possibilities is really big,
              we’ll need to improve beyond our current abilities, eventually.
            </p>
          </Section>
        </GridLayout>
      </Page>
      <NewsletterForm />
    </div>
  );
};

export default Mission;
