// @flow

import _ from "lodash";
import moment from "moment";
import toc from "toc";
import type Moment from "moment";

function trimAny(str, chars) {
  var start = 0,
    end = str.length;

  while (start < end && chars.indexOf(str[start]) >= 0) ++start;

  while (end > start && chars.indexOf(str[end - 1]) >= 0) --end;

  return start > 0 || end < str.length ? str.substring(start, end) : str;
}

toc.anchor = function(s) {
  var slug = require("slug");
  var entities = require("entities");
  s = toc.untag(s);
  s = s.toLowerCase();
  s = entities.decode(s);
  s = s.replace(/['"!]|[.]+$/g, "");
  s = trimAny(s, "?!.");
  s = slug(s);
  s = s.replace(/[:()]+/gi, "-");
  s = s.replace(/[\s-]*([.])[\s-]*/g, "$1");
  s = s.replace(/-+/g, "-");
  s = s.replace(/^-+|-+$/g, "");
  return s;
};

export type Post = {
  slug: string,
  date: Moment,
  title: string,
  author: {
    name: string,
    email: string,
  },
  header?: {
    description: string,
    imageURL?: string,
  },
  nextSlug: ?string,
  summary: ?string,
  __content: string,
  __toc: ?string,
};

const tocOptions = {
  header:
    '<h<%= level %><%= attrs %> id="<%= anchor %>"><%= header %></h<%= level %>>',
  TOC:
    '<div class="LocalNavigation"><div class="TableOfContents"><div class="TableOfContents-Header">Table of Contents</div><%= toc %></div></div>',
  openUL: '<ol data-depth="<%= depth %>">',
  closeUL: "</ol>",
  openLI:
    '<li data-level="H<%= level %>"><a href="#<%= anchor %>"><%= text %></a>',
  closeLI: "</li>",
};

function generateTOC(src: string) {
  const anchorized = toc.anchorize(src, tocOptions);
  const tocHTML = toc.toc(anchorized.headers, tocOptions);
  return { contentHTML: anchorized.html, tocHTML };
}

function parsePosts(
  files: {
    [key: string]: Object,
  },
  parser: DomParser
): {
  data: Map<string, Post>,
  slugs: Array<string>,
  title: (slug: string) => string,
} {
  const filenames = _.keys(files);
  const data = filenames.reduce((memo, slug) => {
    const tokenizedSlug = slug.match(/^(\d{4}-\d{2}-\d{2})(.*)/);
    if (!tokenizedSlug || !tokenizedSlug[1]) {
      throw new Error("no ^YYYY-MM-DD date in blog filename");
    }
    const date = moment(tokenizedSlug[1]);
    const post = _.assign(files[slug], { slug, date });

    // Add classname to <p> and <a> tags containing <img> tags
    const parsedHTML = parser.parseFromString(post.__content, "text/html");
    const pTags = parsedHTML.querySelectorAll("p");
    pTags.forEach((pTag) => {
      const img = pTag.querySelector("img");
      if (img) {
        pTag.classList.add("Image"); // Add the classname "Image" to the <p> tag
      }
    });
    const aTags = parsedHTML.querySelectorAll("a");
    aTags.forEach((aTag) => {
      const img = aTag.querySelector("img");
      if (img) {
        aTag.classList.add("Image"); // Add the classname "Image" to the <p> tag
      }
    });
    post.__content = parsedHTML.documentElement.innerHTML;

    const { contentHTML, tocHTML } = generateTOC(post.__content);
    post.__content = contentHTML;
    if (post.summary) {
      post.__toc = tocHTML;
    }
    memo.set(slug, post);
    return memo;
  }, new Map());

  const title = (slug: string) => data.get(slug).title || slug;

  const slugs = _.sortBy([...data.keys()]).reverse();

  slugs.forEach((slug: string, i: number) => {
    const nextSlug = slugs[i + 1];
    if (!nextSlug) {
      return;
    }
    data.set(slug, _.assign(data.get(slug), { nextSlug }));
  });

  return { data, slugs, title };
}

export default parsePosts;
