// @flow

import * as React from "react";

import Page from "../../components/Page/Page";
import NewsletterForm from "../../components/NewsletterForm/NewsletterForm";
import GridLayout from "../../components/GridLayout/GridLayout";
import "./Participate.css";

const Participate = () => (
  <Page className="ExperimentParticipant" title="Participate in Experiments">
    <GridLayout>
      <h2>Not currently accepting applications for experiment participants</h2>
      <p>
        We were previously recruiting participants for our experiments on
        factored evaluation. We received a large number of strong applications,
        and we now have plenty of experiment participants for the time being.{" "}
      </p>
      <p>
        If you'd like to stay updated on our work and get informed about future
        opportunities to participate in experiments, please sign up for our
        newsletter below.{" "}
      </p>
    </GridLayout>
    <NewsletterForm />
  </Page>
);

export default Participate;
