// @flow

import * as React from "react";
import Helmet from "react-helmet";

type Props = {
    title?: string,
    description?: string,
    imageURL?: string
};

const MetaHeader = (props: Props) => {
    const title = props.title || "Ought";
    const description = props.description;
    const imageURL =
        props.imageURL || `https://image.thum.io/get/png/width/1142/crop/631/https://ought.org${window.location.pathname}?v=2`;
    return (
        <Helmet title={title}>
        {description && <meta name="description" content={description} />}
        <meta property="og:title" content={title} />
        {description && <meta property="og:description" content={description} />}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@oughtinc" />
        <meta property="og:image" content={imageURL} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1142" />
        <meta property="og:image:height" content="600" />
        <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
        </Helmet>
    );
};

export default MetaHeader;
