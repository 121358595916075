// @flow

import * as React from "react";
import classNames from "classnames";

import TextWithPointers from "../TextWithPointers/TextWithPointers";

import "./Workspace.css";

type Props = {
  question?: string,
  children?: React.Node,
  response?: string,
  unstructured?: boolean
};

const Workspace = (props: Props) => (
  <div className="Workspace">
    {!props.unstructured && (
      <div className="Workspace-Question">
        <TextWithPointers text={props.question} />
      </div>
    )}
    <div
      className={classNames("Workspace-Body", {
        "Workspace-Body-IsEmpty": !props.children
      })}
    >
      {props.children || "(empty body)"}
    </div>
    {!props.unstructured && (
      <div
        className={classNames("Workspace-Response", {
          "Workspace-Response-IsEmpty": !props.response
        })}
      >
        <TextWithPointers text={props.response || "(empty response)"} />
      </div>
    )}
  </div>
);

export default Workspace;
