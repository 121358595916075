// @flow

import type { RouterHistory } from "react-router";

import classNames from "classnames";
import * as React from "react";
import { Component } from "react";
import MediaQuery from "react-responsive";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";

import "./NavigationRight.css";

type NavigationLinkProps = {
  children?: React.Node,
  to: string,
  exact?: boolean,
  onClick?: () => void,
};

const NavigationLink = (props: NavigationLinkProps) => (
  <NavLink
    to={props.to}
    exact={props.exact}
    activeClassName="active"
    onClick={props.onClick}
  >
    {props.children}
  </NavLink>
);

type Props = {
  history: RouterHistory,
};

type State = {
  menuVisible: boolean,
};

class NavigationRight extends Component<Props, State> {
  static defaultProps: Props;

  constructor(props: Props) {
    super();
    this.state = {
      menuVisible: false,
    };
  }

  handleToggleClick = (e: Event) => {
    this.setState({
      menuVisible: !this.state.menuVisible,
    });
    e.preventDefault();
  };

  hideMenu = () => {
    this.setState({
      menuVisible: false,
    });
  };

  render() {
    return (
      <nav className="NavigationRight">
        <div className="NavigationRight-Toggle">
          <a href="#menu" onClick={this.handleToggleClick}>
            Menu
          </a>
        </div>
        <MediaQuery maxWidth={650}>
          {(matches) => {
            if ((matches && this.state.menuVisible) || !matches) {
              const className = classNames("NavigationRight-Links", {
                "NavigationRight-MediaQuery-Large": !matches,
              });
              return (
                <div className={className}>
                  <NavigationLink to="/mission" onClick={this.hideMenu}>
                    Mission
                  </NavigationLink>
                  <NavigationLink to="/elicit" onClick={this.hideMenu}>
                    Elicit
                  </NavigationLink>
                  <NavigationLink to="/team" onClick={this.hideMenu}>
                    Team
                  </NavigationLink>
                  <NavigationLink to="/updates" onClick={this.hideMenu}>
                    Updates
                  </NavigationLink>
                </div>
              );
            } else {
              return null;
            }
          }}
        </MediaQuery>
      </nav>
    );
  }
}

export default withRouter(NavigationRight);
