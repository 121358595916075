// @flow

import * as React from "react";

const CognitiveTasks = () => (
  <ul>
    <li>Read this book and tell me why x did y.</li>
    <li>
      Provide a detailed analysis of the pros and cons of these two products.
    </li>
    <li>Tell me how to invest $100k to achieve the most social good.</li>
    <li>
      Write a paper on NLP that substantially advances the state of the art.
    </li>
  </ul>
);

export default CognitiveTasks;
