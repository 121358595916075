// @flow

import { Component } from "react";

class ScrollToTopOnMount extends Component<{}> {
  static defaultProps: {};

  componentDidMount() {
    if (window.location.hash === "") {
      window.scrollTo(0, 0);
    } else {
      const hash = window.location.hash;
      if (hash.length > 1) {
        const element = document.getElementById(hash.slice(1));
        if (element) {
          element.scrollIntoView();
        }
      }
    }
  }

  render() {
    return null;
  }
}

export default ScrollToTopOnMount;
